<template>
    <div class="task">
        <div class="task__content-wrapper">
            <router-view :key="$route.fullPath" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Tasks',

        beforeRouteEnter(to, from, next) {
            if (to.path === '/tasks' || to.path === 'tasks/') {
                next('/tasks/list');
            } else {
                next();
            }
        },

        beforeRouteUpdate(to, from, next) {
            if (to.path === '/tasks' || to.path === 'tasks/') {
                this.$router.replace('/tasks/list').catch(err => {
                    if (err.name !== 'NavigationDuplicated') {
                        throw err;
                    }
                });
            } else {
                next();
            }
        },
    };
</script>

<style lang="scss" scoped></style>
