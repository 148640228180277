<template>
    <div class="activity-page">
        <h1>Activity</h1>

        <div class="tabs">
            <p>Show:</p>
            <button :class="{ active: activeTab === 'comments' }" @click="activeTab = 'comments'">Comments</button>
            <button :class="{ active: activeTab === 'history' }" @click="activeTab = 'history'">History</button>
            <button :class="{ active: activeTab === 'tasks' }" @click="activeTab = 'tasks'">Task Activity</button>
        </div>
        <section v-if="activeTab === 'comments'">
            <h1>WIP</h1>
            <p>Comments section</p>
        </section>
        <section v-if="activeTab === 'history'">
            <h1>WIP</h1>
            <p>History section</p>
        </section>
        <section v-if="activeTab === 'tasks'">
            <div class="header-section">
                <h2>Task Activity</h2>
                <p>Total Spent Time: {{ formatSpentTime(project.project_time) }}</p>
            </div>
            <div class="task-grid">
                <div class="task-grid-header">
                    <div>Name</div>
                    <div>Reference</div>
                    <div>Time Spent</div>
                </div>
                <div v-if="!tasks || tasks.length === 0" class="no-tasks">
                    {{ $t('tasks.noTasks') }}
                </div>

                <div v-for="task in tasks" :key="task.id" class="task-grid-row">
                    <div>{{ truncate(task.attributes.task_name, 20) }}</div>
                    <div>
                        <span class="task-ref" @click="showReference(task)">{{ task.attributes.ref }}</span>
                    </div>
                    <div>{{ formatSpentTime(task.attributes.total_spent_time) }}</div>
                </div>
            </div>
        </section>
        <div v-if="tasks && tasks.length" class="pagination">
            <at-pagination :total="totalItems" :current="page" :page-size="itemsPerPage" @page-change="onPageChange">
                <template #previous-text>{{ $t('pagination.previous') }}</template>
                <template #next-text>{{ $t('pagination.next') }}</template>
            </at-pagination>
        </div>
    </div>
</template>

<script>
    import TaskService from '@/services/resource/task.service';

    export default {
        props: { project: { type: Object, required: true } },
        data() {
            return {
                activeTab: 'tasks',
                tasks: null,

                page: +(this.$route.query.page || 1),
                totalItems: 0,
                itemsPerPage: 10,
                queryParams: {
                    page: this.$route.query.page,
                },

                taskService: new TaskService(),
            };
        },
        async created() {
            await this.fetchTasks();
        },
        methods: {
            showReference(task) {
                this.$router.push({ name: 'task.view', params: { id: task.id } });
            },

            truncate(text, length) {
                return text.length > length ? text.substring(0, length) + '...' : text;
            },

            formatSpentTime(seconds) {
                if (!seconds) return '-';
                const hrs = Math.floor(seconds / 3600);
                const mins = Math.floor((seconds % 3600) / 60);
                return `${hrs}h ${mins.toString().padStart(2, '0')}m`;
            },

            async onPageChange(page) {
                if (this.queryParams.page === page) return;
                this.queryParams.page = page;
                this.page = page;
                await this.fetchTasks();
            },

            async fetchTasks() {
                const queryParams = {
                    page: this.page,
                    project_ids: [this.project.id],
                };
                try {
                    const res = await this.taskService.getWithFilters(queryParams);

                    const { data, pagination } = res.data;
                    this.totalItems = pagination.total;
                    this.page = pagination.page;
                    this.itemsPerPage = 1;
                    this.tasks = data;
                } catch ({ response }) {
                    if (process.env.NODE_ENV === 'development') {
                        console.warn(response ? response : 'request is canceled');
                    }
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    .header-section {
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
    }
    .tabs {
        display: flex;
        gap: 10px;
        margin-bottom: 20px;
    }

    .tabs button {
        .theme-dark & {
            color: #c4c4cf;

            &:hover {
                color: #ffa500;
            }
        }
    }

    .tabs button.active {
        font-weight: bold;
        color: #2563eb;

        .theme-dark & {
            color: #ffa500;
        }
    }

    .no-tasks {
        text-align: center;
        font-size: 1.2rem;
        color: #777;
        margin-top: 20px;
    }

    section {
        margin-bottom: 20px;
    }

    .pagination {
        display: flex;
        justify-content: flex-end;
        max-width: 1400px;
        margin: 0 auto;
        margin-top: auto;
        padding-top: 16px;
    }

    .task-grid {
        display: grid;
        grid-template-columns: 1fr;
        gap: 8px;
        border: 1px solid #ccc;
        border-radius: 10px;
        padding: 10px;
        background: #fff;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

        .theme-dark & {
            background: #2c2c2c;
            border-color: #555;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
        }
    }

    .task-grid-header,
    .task-grid-row {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;
        padding: 8px;
    }

    .task-grid-header {
        font-weight: bold;
        border-bottom: 2px solid #f0f0f0;
        text-transform: uppercase;
        color: #333;
        text-align: center;
        padding: 14px;

        .theme-dark & {
            border-bottom: 3px solid #3a3a3a;
            color: #ffa500;
        }
    }

    .task-grid-row {
        background: #fafafa;
        transition: background 0.2s ease-in-out;
        text-align: center;
        border-radius: 6px;

        &:hover {
            background: #ececec;
        }

        .theme-dark & {
            background-color: #3a3a3a;
            color: #ffa500;

            &:hover {
                background: #4a4a4a;
                color: #c4c4cf;
            }
        }
    }

    .task-grid-row div {
        padding: 10px 15px;
    }

    .task-ref {
        text-decoration: underline;
        cursor: pointer;

        &:hover {
            color: #007bff;
        }
    }
</style>
