<template>
    <div class="activity">
        <div class="header">
            <span class="title">{{ $t('task.activity') }}</span>
            <div class="sort-toggle">
                <button @click="toggleSort">
                    {{ sortNewest ? $t('task.newestFirst') : $t('task.oldestFirst') }}
                    <font-awesome-icon :icon="sortNewest ? 'arrow-down' : 'arrow-up'" />
                </button>
            </div>
        </div>

        <div class="view-toggle">
            {{ $t('task.show') }}:
            <button :class="{ active: view === 'comments' }" @click="view = 'comments'">
                {{ $t('task.comments') }}
            </button>
            <button :class="{ active: view === 'history' }" @click="view = 'history'">{{ $t('task.history') }}</button>
            <button :class="{ active: view === 'intervals' }" @click="view = 'intervals'">
                {{ $t('task.time-intervals') }}
            </button>
        </div>

        <div v-if="view === 'comments'" class="comment-input">
            <div class="form-group">
                <div v-if="!isEditing" class="comment-preview" @click="enableEditing">
                    <p v-if="newComment" v-html="newComment"></p>
                    <p v-else class="placeholder">{{ $t('task.writeComment') }}</p>
                </div>

                <vue-editor v-else ref="editor" v-model="newComment" :editorToolbar="editorToolbar" class="editor" />

                <p v-if="errors.newComment" class="error-message">{{ errors.newComment }}</p>
            </div>
            <div class="form-actions">
                <button
                    v-if="isEditing"
                    class="at-btn crud__control-items__item at-btn--primary at-btn--large"
                    @click="addComment"
                >
                    {{ $t('task.post') }}
                </button>
                <button v-if="isEditing" class="cancel-button" @click="isEditing = false">
                    {{ $t('task.cancel') }}
                </button>
            </div>
        </div>

        <ul v-if="view === 'comments'" class="comment-list">
            <li v-for="comment in sortedComments" :key="comment.attributes.id" class="comment-item">
                <div class="comment-header">
                    <user-avatar :user="comment.attributes.user.attributes" />
                    <div class="user-info">
                        <strong>{{
                            `${comment.attributes.user.attributes.first_name} ${comment.attributes.user.attributes.last_name}`
                        }}</strong>
                        <span class="time">{{ formatTime(comment.attributes.timestamp) }}</span>
                    </div>
                </div>

                <div class="comment-body">
                    <vue-editor
                        v-if="editingCommentId === comment.attributes.id"
                        ref="editor"
                        v-model="editedContent"
                        :editorToolbar="editorToolbar"
                        class="editor"
                    />
                    <p v-else class="comment-text" v-html="comment.attributes.content"></p>
                </div>

                <div class="comment-actions">
                    <template v-if="editingCommentId === comment.attributes.id">
                        <button
                            class="save-button at-btn crud__control-items__item at-btn--primary at-btn--large"
                            @click="saveEdit(comment.attributes.id)"
                        >
                            {{ $t('task.save') }}
                        </button>
                        <button class="cancel-button" @click="cancelEdit">{{ $t('task.cancel') }}</button>
                    </template>
                    <template v-else>
                        <button
                            v-if="comment.attributes.user.attributes.id == user.id"
                            class="btn edit-btn at-btn crud__control-items__item at-btn--primary at-btn--large"
                            @click="startEditing(comment)"
                        >
                            <font-awesome-icon :icon="['fas', 'pen-to-square']" />
                            {{ $t('task.edit') }}
                        </button>
                        <button
                            v-if="comment.attributes.user.attributes.id == user.id"
                            class="btn delete-btn delete-button at-btn at-btn--error at-btn--large action-button"
                            @click="deleteComment(comment.attributes.id)"
                        >
                            <font-awesome-icon :icon="['fas', 'trash']" />
                            {{ $t('task.delete') }}
                        </button>
                    </template>
                </div>
            </li>

            <div v-if="comments.length > 0" class="pagination">
                <at-pagination
                    :total="totalItems"
                    :current="page"
                    :page-size="itemsPerPage"
                    class="crud__pagination"
                    @page-change="onPageChange"
                >
                    <template #previous-text>{{ $t('pagination.previous') }}</template>
                    <template #next-text>{{ $t('pagination.next') }}</template>
                </at-pagination>
            </div>
        </ul>

        <ul v-if="view === 'history'" class="history-list">
            <template v-if="history.length > 0">
                <li v-for="event in sortedHistory" :key="event.attributes.id" class="history-item">
                    <div class="history-header">
                        <user-avatar :user="event.attributes.user.attributes" />
                        <div class="user-info">
                            <strong>{{
                                `${event.attributes.user.attributes.first_name} ${event.attributes.user.attributes.last_name}`
                            }}</strong>
                            <span class="time">{{ formatTime(event.attributes.timestamp) }}</span>
                        </div>
                    </div>
                    <p class="history-text">
                        <template v-if="event.attributes.new_value && event.attributes.old_value">
                            <p>
                                {{ $t('task.fieldChangedFrom') }} <strong>"{{ event.attributes.field }}"</strong>
                                {{ $t('task.from') }}:
                            </p>
                            <p class="old-value" v-html="event.attributes.old_value"></p>

                            <p class="to-text">{{ $t('task.to') }}:</p>
                            <p class="new-value" v-html="event.attributes.new_value"></p>
                        </template>
                        <template v-else-if="event.attributes.new_value">
                            <p>
                                {{ $t('task.fieldAdded') }} <strong>{{ event.attributes.field }}</strong
                                >:
                                <strong class="inline new-value">{{ event.attributes.new_value }}</strong>
                            </p>
                        </template>
                        <template v-else-if="event.attributes.old_value">
                            <p>
                                {{ $t('task.fieldRemoved') }} <strong>{{ event.attributes.field }}</strong
                                >:
                                <strong class="inline old-value">{{ event.attributes.old_value }}</strong>
                            </p>
                        </template>
                    </p>
                </li>

                <div v-if="history.length > 0" class="pagination">
                    <at-pagination
                        :total="totalHistoryItems"
                        :current="historyPage"
                        :page-size="itemsPerHistoryPage"
                        class="crud__pagination"
                        @page-change="onHistoryPageChange"
                    >
                        <template #previous-text>{{ $t('pagination.previous') }}</template>
                        <template #next-text>{{ $t('pagination.next') }}</template>
                    </at-pagination>
                </div>
            </template>

            <template v-else>
                <li class="history-empty">
                    <p>{{ $t('task.noHistory') }}</p>
                </li>
            </template>
        </ul>

        <ul v-if="view === 'intervals'" class="time-intervals">
            <time-intervals-details :task="task" />
        </ul>
    </div>
</template>

<script>
    import { VueEditor } from 'vue2-editor';
    import UserAvatar from '@/components/UserAvatar.vue';
    import TaskCommentService from '../services/task-comment.service';
    import TaskChangeLogService from '../services/task-change-log.service';
    import { mapGetters } from 'vuex';
    import TimeIntervalsDetails from './TimeIntervalsDetails.vue';

    let reconnectInterval = 5000;
    let maxRetries = 10;
    let retries = 0;

    export default {
        components: {
            VueEditor,
            UserAvatar,
            TimeIntervalsDetails,
        },
        props: {
            task: { type: Object, required: true },
            wsAddChangeLog: { type: Object, default: null },
        },
        data() {
            return {
                view: 'comments',
                sortNewest: true,
                newComment: '',
                createdSort: 'desc',
                comments: [],
                history: [],
                errors: {},

                editingCommentId: null,
                editedContent: '',

                page: +(this.$route.query.page || 1),
                totalItems: 0,
                itemsPerPage: 15,

                historyPage: +(this.$route.query.page || 1),
                totalHistoryItems: 0,
                itemsPerHistoryPage: 15,

                queryParams: {
                    page: this.$route.query.page,
                    historyPage: this.$route.query.page,
                },

                isEditing: false,
                editorToolbar: [
                    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
                    [{ indent: '-1' }, { indent: '+1' }],
                    [{ color: [] }, { background: [] }],
                ],

                commentService: new TaskCommentService(),
                historyService: new TaskChangeLogService(),

                wsUrl: process.env.VUE_APP_WS_URL || '',
                ws: null,
            };
        },
        computed: {
            ...mapGetters('user', ['user']),
            sortedComments() {
                return this.comments
                    .slice()
                    .sort((a, b) => (this.sortNewest ? b.timestamp - a.timestamp : a.timestamp - b.timestamp));
            },
            sortedHistory() {
                return this.history
                    .slice()
                    .sort((a, b) => (this.sortNewest ? b.timestamp - a.timestamp : a.timestamp - b.timestamp));
            },
        },
        async mounted() {
            await this.fetchComments();
            await this.fetchHistory();
            this.connectWebSocket();
        },
        methods: {
            toggleSort() {
                this.sortNewest = !this.sortNewest;
                this.createdSort = this.createdSort == 'desc' ? 'asc' : 'desc';
                if (this.view == 'comments') this.fetchComments();
                if (this.view == 'history') this.fetchHistory();
            },

            enableEditing() {
                this.isEditing = true;
                this.$nextTick(() => {
                    const editorInstance = this.$refs.editor?.$el.querySelector('.ql-editor');
                    if (editorInstance) {
                        editorInstance.focus();
                    }
                });
            },
            startEditing(comment) {
                this.editingCommentId = comment.attributes.id;
                this.editedContent = comment.attributes.content;
            },
            saveEdit(commentId) {
                if (this.editedContent.trim()) {
                    this.editComment(commentId, this.editedContent);
                }
                this.editingCommentId = null;
            },
            cancelEdit() {
                this.editingCommentId = null;
                this.editedContent = '';
            },

            async onPageChange(page) {
                await this.handlePageChange(page);
            },

            async onHistoryPageChange(page) {
                await this.handleHistoryPageChange(page);
            },

            handleHistoryPageChange(page) {
                if (this.queryParams.historyPage === page) {
                    return;
                }
                this.queryParams.historyPage = page;
                return this.fetchHistory();
            },

            handlePageChange(page) {
                if (this.queryParams.page === page) {
                    return;
                }
                this.queryParams.page = page;
                return this.fetchComments();
            },

            sortComments() {
                this.comments.sort((a, b) => {
                    const timeA = new Date(a.attributes.created_at).getTime();
                    const timeB = new Date(b.attributes.created_at).getTime();

                    return this.createdSort === 'desc' ? timeB - timeA : timeA - timeB;
                });
            },

            validateForm() {
                this.errors = {};
                if (!this.newComment || !this.newComment.length > 2) {
                    this.errors.newComment = 'Field must be filled';
                }

                return Object.keys(this.errors).length === 0;
            },

            async addComment() {
                if (!this.validateForm()) return;

                const formData = new FormData();
                formData.append('comment[task_id]', this.task.id);
                formData.append('comment[content]', this.newComment);

                try {
                    await this.commentService.save(formData);

                    this.sortComments();
                    this.successSaveMessage();
                } catch (error) {
                    console.log('Error create comment request', error);
                }

                this.isEditing = false;
                this.expanded = false;
                this.newComment = '';
            },

            async fetchComments() {
                const formData = new FormData();
                formData.append('comment[task_id]', this.task.id);
                formData.append('sort[created_at]', this.createdSort);
                if (this.queryParams.page) {
                    formData.append('page', this.queryParams.page);
                }

                try {
                    const res = await this.commentService.getAll(formData);

                    const { data, pagination } = res.data;
                    this.totalItems = pagination.total;
                    this.page = pagination.page;
                    this.itemsPerPage = 1;

                    this.comments = data.map(comment => ({
                        ...comment,
                        attributes: {
                            ...comment.attributes,
                            timestamp: this.timestampFormmat(comment.attributes.created_at),
                        },
                    }));
                } catch (error) {
                    console.log('Error task comments reqquest', error);
                }
            },

            async fetchHistory() {
                const formData = new FormData();
                formData.append('change_log[object_id]', this.task.id);
                formData.append('change_log[object_model_name]', 'task');
                formData.append('sort[created_at]', this.createdSort);

                if (this.queryParams.historyPage) {
                    formData.append('page', this.queryParams.historyPage);
                }

                try {
                    const res = await this.historyService.getAll(formData);

                    const { data, pagination } = res.data;
                    this.totalHistoryItems = pagination.total;
                    this.historyPage = pagination.page;
                    this.itemsPerHistoryPage = 1;

                    this.history = data.map(history => ({
                        ...history,
                        attributes: {
                            ...history.attributes,
                            timestamp: this.timestampFormmat(history.attributes.created_at),
                        },
                    }));
                } catch (error) {
                    console.log('Error task comments reqquest', error);
                }
            },

            async deleteComment(id) {
                try {
                    await this.commentService.delete(id);

                    this.successDeleteMessage();
                } catch (error) {
                    console.log('Error delete comment request', error);
                }
            },

            async editComment(id, data) {
                const formData = new FormData();
                formData.append('comment[content]', data);

                try {
                    await this.commentService.update(formData, id);

                    this.successSaveMessage();
                } catch (error) {
                    console.log('Error update comment request', error);
                }
            },

            formatTime(timestamp) {
                const diff = (new Date().getTime() - timestamp) / 1000;
                if (diff < 60) return `${Math.floor(diff)} seconds ago`;
                if (diff < 3600) return `${Math.floor(diff / 60)} minutes ago`;
                if (diff < 86400) return `${Math.floor(diff / 3600)} hours ago`;

                return new Date(timestamp).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                });
            },

            timestampFormmat(isoString) {
                return new Date(isoString).getTime();
            },

            successDeleteMessage() {
                this.$Notify({
                    type: 'success',
                    title: this.$t('notification.record.delete.success.title'),
                    message: this.$t('notification.record.delete.success.message'),
                });
            },

            successSaveMessage() {
                this.$Notify({
                    type: 'success',
                    title: this.$t('notification.save.success.title'),
                    message: this.$t('notification.save.success.message'),
                });
            },

            connectWebSocket() {
                if (!this.wsUrl || !this.task) return;

                if (this.ws && this.ws.readyState !== WebSocket.CLOSED) {
                    console.warn('WebSocket connection already exists');
                    return;
                }

                this.ws = new WebSocket(this.wsUrl);

                this.ws.onopen = () => {
                    retries = 0;

                    const subTask = {
                        command: 'subscribe',
                        identifier: JSON.stringify({
                            channel: 'CommentChannel',
                            task_id: this.task.id,
                        }),
                    };

                    this.ws.send(JSON.stringify(subTask));
                };

                this.ws.onmessage = event => {
                    const data = JSON.parse(event.data);
                    if (data.type !== 'ping' && data.message) {
                        const { action, comment, comment_id } = data.message;

                        const actions = {
                            update_comment: () => this.wsUpdateComment(comment),
                            new_comment: () => this.wsAddComment(comment),
                            remove_comment: () => this.wsRemoveComment(comment_id),
                        };

                        if (actions[action]) {
                            actions[action]();
                        } else {
                            console.warn(`Unhandled action: ${action}`);
                        }
                    }
                };

                this.ws.onclose = () => {
                    if (process.env.NODE_ENV === 'development') {
                        console.warn('WebSocket connection closed');
                    }
                    this.retryWebSocketConnection();
                };

                this.ws.onerror = error => {
                    this.retryWebSocketConnection();
                };
            },
            retryWebSocketConnection() {
                if (retries < maxRetries) {
                    retries += 1;
                    setTimeout(() => {
                        this.connectWebSocket();
                    }, reconnectInterval);
                } else {
                    if (process.env.NODE_ENV === 'development') {
                        console.warn('The maximum number of reconnections has been reached. TimerChannel');
                    }
                }
            },

            wsUpdateComment(data) {
                const comment = data.data;
                const index = this.comments.findIndex(c => c.attributes.id === comment.attributes.id);
                if (index !== -1) {
                    const updetedComment = {
                        ...this.comments[index],
                        attributes: {
                            ...this.comments[index].attributes,
                            content: comment.attributes.content,
                            timestamp: this.timestampFormmat(comment.attributes.created_at),
                        },
                    };
                    this.$set(this.comments, index, updetedComment);
                }
            },

            wsAddComment(data) {
                let comment = data.data;
                comment = {
                    ...comment,
                    attributes: {
                        ...comment.attributes,
                        timestamp: this.timestampFormmat(comment.attributes.created_at),
                    },
                };
                this.comments.unshift(comment);
            },

            wsRemoveComment(id) {
                this.comments = this.comments.filter(comment => comment.attributes.id != id);
            },
        },

        watch: {
            wsAddChangeLog(data) {
                let changesLog = data.data;
                changesLog = {
                    ...changesLog,
                    attributes: {
                        ...changesLog.attributes,
                        timestamp: this.timestampFormmat(changesLog.attributes.created_at),
                    },
                };
                this.history.unshift(changesLog);
            },
        },
    };
</script>

<style scoped lang="scss">
    .history-empty {
        text-align: center;
        font-size: 16px;
        color: #888;
        padding: 20px;
    }

    .activity {
        background: #fff;
        padding: 1rem;
        border-radius: 0.5rem;
        border: 1px solid #e5e7eb;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        width: 100%;

        .theme-dark & {
            background-color: #333;
            border-color: #444;
        }
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .title {
        font-weight: bold;
        font-size: 1.2rem;
    }

    .sort-toggle button {
        background: none;
        border: none;
        cursor: pointer;
        font-size: 1rem;
        color: #6b7280;

        .theme-dark & {
            color: #ffa500;

            &:hover {
                color: #c4c4cf;
            }
        }
    }

    .view-toggle {
        margin: 0.5rem 0;
    }

    .form-actions {
        display: flex;
        gap: 0.5rem;
    }

    .view-toggle button {
        border: none;
        background: none;
        font-size: 1rem;
        color: #6b7280;
        cursor: pointer;
        margin-right: 10px;

        .theme-dark & {
            color: #c4c4cf;

            &:hover {
                color: #ffa500;
            }
        }
    }

    .view-toggle button.active {
        font-weight: bold;
        color: #2563eb;

        .theme-dark & {
            color: #ffa500;
        }
    }

    .comment-input {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        margin-bottom: 1rem;
    }

    .comment-input button {
        align-self: flex-end;
    }

    .comment-list,
    .history-list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .comment-item,
    .history-item {
        padding: 0.5rem;
        border-bottom: 1px solid #e5e7eb;

        .theme-dark & {
            border-color: #444;
        }
    }

    .comment-header,
    .history-header {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
    }

    .user-info {
        display: flex;
        flex-direction: column;
        font-size: 0.9rem;
        color: #6b7280;

        .theme-dark & {
            color: #ffa500;
        }
    }

    .time {
        font-size: 0.8rem;
        color: #9ca3af;
    }

    .comment-text,
    .history-text {
        margin: 10px 0;
        font-size: 1rem;
    }

    .comment-body {
        margin-top: 10px;
    }

    .cancel-button {
        margin-top: 0.5rem;
        padding: 0.5rem 1rem;
        font-size: 1rem;
        font-weight: 600;
        color: #374151;
        border: none;
        border-radius: 0.375rem;
        cursor: pointer;
        transition: color 0.3s ease-in-out, background 0.4s ease-in-out;

        &:hover {
            background: #c4c4cf;
        }

        .theme-dark & {
            color: #c4c4cf;

            &:hover {
                color: #222;
            }
        }
    }

    .save-button {
        margin-top: 0.5rem;
        padding: 0.5rem 1rem;
        border: none;
        border-radius: 0.375rem;
    }

    .comment-actions {
        display: flex;
        gap: 10px;
        max-width: 75px;
    }

    .pagination {
        margin: 15px 0;
        display: flex;
        justify-content: flex-end;
    }

    .error-message {
        color: #e74c3c;
        font-size: 12px;
        margin-top: 5px;

        .theme-dark & {
            color: #ff4c4c;
        }
    }
    .history-text {
        font-size: 14px;
        color: #333;
        font-weight: bold;
    }

    .old-value,
    .new-value {
        display: inline-block;
        padding: 6px 10px;
        border-radius: 6px;
        font-weight: bold;
        max-width: 100%;
        word-wrap: break-word;
    }

    .inline {
        margin-left: 5px;
    }

    .old-value {
        background-color: #ffebeb;
        border-left: 4px solid #b30000;

        .theme-dark & {
            background-color: #444;
        }
    }

    .to-text {
        font-size: 14px;
        font-weight: bold;
        margin: 4px 0;
        color: #666;
    }

    .new-value {
        background-color: #e6ffed;
        border-left: 4px solid #006600;

        .theme-dark & {
            background-color: #444;
        }
    }

    .comment-preview {
        flex: 1;
        padding: 0.5rem;
        min-height: 100px;
        border: 1px solid #d1d5db;
        border-radius: 0.375rem;

        .theme-dark & {
            background-color: #333;
            border-color: #555;
            color: #ffa500;
        }

        &:focus {
            border-color: #176bda;
            outline: none;
            box-shadow: 0 0 10px rgba(0, 50, 212, 0.2);
            .theme-dark & {
                border-color: #ffa500;
                box-shadow: 0 0 10px rgba(255, 165, 0, 0.4);
            }
        }
    }
    .placeholder {
        color: #999;
    }

    .editor {
        & ::v-deep .quill-editor {
            border: 1px solid #ccc;
            border-radius: 5px;
            padding: 10px;
        }

        & ::v-deep .ql-toolbar {
            .theme-dark & {
                border: 1px solid #555;
                border-radius: 5px 5px 0 0;
            }
        }

        & ::v-deep .ql-container {
            .theme-dark {
                border: 1px solid #555;
                border-radius: 0 0 5px 5px;
                min-height: 200px;
            }
        }

        & ::v-deep .ql-editor {
            .theme-dark & {
                p {
                    color: #ffa500;
                }
            }
        }

        .theme-dark & ::v-deep .ql-toolbar button.ql-bold .ql-stroke,
        .theme-dark & ::v-deep .ql-toolbar button.ql-italic .ql-stroke,
        .theme-dark & ::v-deep .ql-toolbar button.ql-underline .ql-stroke,
        .theme-dark & ::v-deep .ql-toolbar button.ql-strike .ql-stroke,
        .theme-dark & ::v-deep .ql-toolbar button.ql-link .ql-stroke,
        .theme-dark & ::v-deep .ql-toolbar button .ql-fill,
        .theme-dark & ::v-deep .ql-toolbar span.ql-picker-label,
        .theme-dark & ::v-deep .ql-toolbar button .ql-stroke {
            stroke: #ffa500 !important;
        }

        .theme-dark & ::v-deep .ql-toolbar .ql-formats span.ql-picker-options {
            background-color: #555;
            color: #ffa500;
        }

        .theme-dark & ::v-deep .ql-toolbar span:hover .ql-fill .ql-color-label {
            color: #c4c4cf !important;
            stroke: #c4c4cf !important;
        }

        .theme-dark & ::v-deep .ql-toolbar span .ql-stroke {
            stroke: #ffa500;
        }

        & ::v-deep .ql-toolbar button:hover .ql-stroke,
        & ::v-deep .ql-toolbar button:focus .ql-stroke,
        & ::v-deep .ql-toolbar button.ql-active .ql-stroke,
        & ::v-deep .ql-toolbar button:hover .ql-fill,
        & ::v-deep .ql-toolbar button:focus .ql-fill,
        & ::v-deep .ql-toolbar button.ql-active .ql-fill {
            color: #c4c4cf !important;
            stroke: #c4c4cf !important;
        }

        .ql-color-label .ql-stroke .ql-transparent {
            color: #c4c4cf !important;
            stroke: #c4c4cf !important;
        }
        .theme-dark & {
            background-color: #333;
        }
    }
</style>
