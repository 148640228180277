<template>
    <div class="web-links">
        <div class="header">
            <label class="title">
                {{ $t('task.webLinks') }}
                <span class="attachment-count">{{ links.length }}</span>
            </label>
            <button v-if="links.length" class="icon-button" @click="showForm = true">
                <font-awesome-icon :icon="['fas', 'plus']" />
            </button>
        </div>

        <ul v-if="links.length" class="links-list">
            <li v-for="link in links" :key="link.id" class="link-item">
                <div class="left-section">
                    <font-awesome-icon :icon="['fas', 'link']" />
                    <a :href="link.url" target="_blank" class="link-text">{{ link.name }}</a>
                </div>
                <button v-if="hasDelete" class="delete-button" @click="removeLink(link.id)">
                    <font-awesome-icon :icon="['fas', 'times']" />
                </button>
            </li>
        </ul>

        <div v-if="!links.length || showForm" class="add-link-form">
            <div class="input-group">
                <div class="form-group" :class="{ 'has-error': errors.url }">
                    <label>{{ $t('task.url') }}:</label>
                    <input v-model="newLink.url" type="url" :placeholder="$t('task.enterUrl')" />
                    <p v-if="errors.url" class="error-message">{{ errors.url }}</p>
                </div>
                <div class="form-group" :class="{ 'has-error': errors.text }">
                    <label>{{ $t('task.linkText') }}:</label>
                    <input v-model="newLink.text" type="text" :placeholder="$t('task.linkTextPlaceholder')" />
                    <p v-if="errors.text" class="error-message">{{ errors.text }}</p>
                </div>
            </div>
            <div class="form-actions">
                <button
                    class="link-button save-button at-btn crud__control-items__item at-btn--primary at-btn--large"
                    @click="addLink"
                >
                    {{ $t('task.link') }}
                </button>
                <button v-if="links.length" class="cancel-button" @click="showForm = false">
                    {{ $t('task.cancel') }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import TaskLinkService from '../services/task-link.service';
    import TaskPolicy from '@/policies/task.policy.js';
    import { mapGetters } from 'vuex';

    export default {
        props: {
            task: { type: Object, required: true },
        },
        data() {
            return {
                showForm: false,
                newLink: { url: '', text: '' },
                links: [],
                errors: {},

                linkService: new TaskLinkService(),
            };
        },
        computed: {
            ...mapGetters('user', ['user']),
            hasDelete() {
                return TaskPolicy.delete(this.user);
            },
        },
        mounted() {
            this.assinedLinks();
        },
        methods: {
            validateForm() {
                this.errors = {};

                if (!this.newLink.url) {
                    this.errors.url = 'The URL field is required';
                }
                if (!this.newLink.text) {
                    this.errors.text = 'The link text field is required';
                }

                return Object.keys(this.errors).length === 0;
            },
            async addLink() {
                if (!this.validateForm()) return;

                const formData = new FormData();
                formData.append('task_link[url]', this.newLink.url);
                formData.append('task_link[name]', this.newLink.text);
                formData.append('task_link[task_id]', this.task.id);

                try {
                    const { data } = await this.linkService.save(formData);
                    const link = data.data.attributes;

                    this.links.push(link);
                    this.successSaveMessage();
                } catch (error) {
                    console.log('Error Add Link Request', error);
                }

                this.newLink = { url: '', text: '' };
                this.showForm = false;
            },

            async removeLink(id) {
                try {
                    await this.linkService.delete(id);
                    this.links = this.links.filter(link => link.id !== id);
                    this.successDeleteMessage();
                } catch (error) {
                    console.log('Error remove link request', error);
                }
            },
            assinedLinks() {
                this.links = this.task.links;
            },

            successDeleteMessage() {
                this.$Notify({
                    type: 'success',
                    title: this.$t('notification.record.delete.success.title'),
                    message: this.$t('notification.record.delete.success.message'),
                });
            },

            successSaveMessage() {
                this.$Notify({
                    type: 'success',
                    title: this.$t('notification.save.success.title'),
                    message: this.$t('notification.save.success.message'),
                });
            },
        },
    };
</script>

<style scoped lang="scss">
    .web-links {
        background: #fff;
        padding: 1rem;
        border-radius: 0.5rem;
        border: 1px solid #e5e7eb;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        width: 100%;

        .theme-dark & {
            background-color: #333;
            border-color: #555;
        }
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
    }

    .icon-button {
        background: none;
        border: none;
        cursor: pointer;
        font-size: 1.2rem;
        color: #6b7280;

        .theme-dark & {
            color: #ffa500;

            &:hover {
                color: #c4c4cf;
            }
        }
    }

    .icon-button:hover {
        color: #111827;
    }

    .links-list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .link-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0.5rem 1rem;
        border-bottom: 1px solid #e5e7eb;
        transition: background 0.2s ease-in-out;

        .theme-dark & {
            background-color: #333;
            border-color: #444;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
        }
    }

    .link-item:hover {
        background: #f3f4f6;

        .theme-dark & {
            background-color: #444;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.5);
        }
    }

    .link-text {
        text-decoration: none;
        color: #2563eb;

        .theme-dark & {
            color: #ffa500;
        }
    }

    .link-text:hover {
        text-decoration: underline;

        .theme-dark & {
            color: #c4c4cf;
        }
    }

    .left-section {
        display: flex;
        gap: 10px;
    }

    .delete-button {
        background: none;
        border: none;
        cursor: pointer;
        font-size: 1rem;
        color: #ef4444;
        display: none;
    }

    .link-item:hover .delete-button {
        display: block;
    }

    .add-link-form {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .input-group {
        display: flex;
        gap: 10px;
        margin-top: 10px;
        align-items: flex-end;
    }

    .input-group .form-group {
        flex-grow: 1;
        max-width: 100%;
    }

    .form-actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    .save-button {
        white-space: nowrap;
    }

    .input-group input {
        flex: 1;
        padding: 0.5rem;
        border: 1px solid #d1d5db;
        border-radius: 0.375rem;

        .theme-dark & {
            background-color: #333;
            border-color: #555;
            color: #ffa500;
        }

        &:focus {
            border-color: #176bda;
            outline: none;
            box-shadow: 0 0 10px rgba(0, 50, 212, 0.2);
            .theme-dark & {
                border-color: #ffa500;
                box-shadow: 0 0 10px rgba(255, 165, 0, 0.4);
            }
        }
    }

    .form-actions {
        display: flex;
        justify-content: flex-end;
        gap: 0.5rem;
    }

    .cancel-button {
        padding: 0.5rem 1rem;
        font-size: 1rem;
        color: #374151;
        border: none;
        border-radius: 0.375rem;
        cursor: pointer;
        transition: color 0.3s ease-in-out, background 0.4s ease-in-out;

        &:hover {
            background: #c4c4cf;
        }

        .theme-dark & {
            color: #c4c4cf;

            &:hover {
                color: #222;
            }
        }
    }

    .error-message {
        color: #e74c3c;
        font-size: 12px;
        margin-top: 5px;
    }

    .form-group {
        display: flex;
        flex-direction: column;
        max-width: 500px;
        width: 100%;
    }

    .form-group.has-error input {
        border-color: #e74c3c;
    }

    .attachment-count {
        background-color: #d1d5db;
        color: #4b5563;
        font-size: 12px;
        font-weight: 600;
        border-radius: 10px;
        padding: 2px 8px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 24px;

        .theme-dark & {
            background-color: #555;
            color: #ffa500;
        }
    }

    @media (max-width: 500px) {
        .input-group {
            flex-direction: column;
        }
    }
</style>
