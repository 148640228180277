<template>
    <div v-click-outside="closeInput" class="story-points" @click="editing = true">
        <span v-if="!editing" class="story-points-text">
            {{ localStoryPoints !== null ? localStoryPoints : 'Select Point' }}
        </span>

        <div v-else class="input-container">
            <button @click.stop="decrement">−</button>
            <input
                v-model.number="localStoryPoints"
                type="number"
                min="0"
                class="story-points-input"
                @blur="save"
                @keyup.enter="save"
            />
            <button @click.stop="increment">+</button>
        </div>
    </div>
</template>

<script>
    import TasksService from '../../../services/resource/task.service';
    export default {
        props: {
            task: { type: Object, required: true },
        },
        data() {
            return {
                localStoryPoints: 0,
                editing: false,

                taskService: new TasksService(),
            };
        },
        created() {
            this.localStoryPoints = this.task.estimated_points;
        },
        methods: {
            increment() {
                this.localStoryPoints = (this.localStoryPoints || 0) + 1;
            },
            closeInput() {
                this.editing = false;
                if (this.task.estimated_points != this.localStoryPoints) {
                    this.updateTaskStorePoint();
                }
            },
            decrement() {
                if (this.localStoryPoints > 0) {
                    this.localStoryPoints -= 1;
                }
            },
            save() {
                this.editing = false;
                this.updateTaskStorePoint();
            },

            async updateTaskStorePoint() {
                const formData = new FormData();
                formData.append('task[estimated_points]', this.localStoryPoints);
                try {
                    await this.taskService.update(formData, this.task.id);
                } catch (error) {
                    console.log('Error update task Store point request', error);
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    .story-points {
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;
    }

    .story-points-text {
        font-size: 14px;
        padding: 5px;
        border-radius: 4px;
    }

    .input-container {
        display: flex;
        align-items: center;
    }

    button {
        border: none;
        background: #e5e7eb;
        padding: 5px 10px;
        cursor: pointer;
        font-size: 18px;
        border-radius: 4px;
        margin: 0 5px;

        &:hover {
            background: #d1d5db;
        }

        .theme-dark & {
            background-color: #555;
            &:hover {
                background-color: #444;
                border-color: #555;
                color: #c4c4cf;
            }
        }
    }

    .story-points-input {
        width: 50px;
        text-align: center;
        border: 1px solid #ddd;
        border-radius: 4px;
        padding: 5px;

        .theme-dark & {
            border-color: #555;
        }
    }
</style>
