<template>
    <div class="attachments">
        <div class="attachments-header">
            <label class="section-title">
                {{ $t('task.attachments') }} <span class="attachment-count">{{ attachments.length }}</span>
            </label>
            <div v-click-outside="closeDropDowns" class="header-actions">
                <div class="dropdown">
                    <button class="icon-button" @click="toggleDropdown">
                        <font-awesome-icon :icon="['fas', 'ellipsis']" />
                    </button>
                    <div v-if="isDropdownOpen" class="dropdown-menu">
                        <button @click="toggleViewMode">
                            {{ viewMode === 'grid' ? $t('task.switchToListView') : $t('task.switchToGridView') }}
                        </button>
                        <button @click="bulkDownload">
                            {{ $t('task.downloadAll', { count: attachments.length }) }}
                        </button>
                    </div>
                </div>

                <div class="dropdown-container">
                    <button class="icon-button add-button" @click="toggleFileDropdown">
                        <font-awesome-icon :icon="['fas', 'plus']" />
                    </button>

                    <div v-if="isFileDropdownOpen" class="dropdown-form">
                        <div class="form-group" :class="{ 'has-error': errors.newFileName }">
                            <label for="fileNameInput" class="input-label">{{ $t('task.title') }}:</label>
                            <input
                                id="fileNameInput"
                                v-model="newFileName"
                                type="text"
                                :placeholder="$t('task.titlePlaceholder')"
                                class="input"
                                :class="{ error: errors.newFileName }"
                            />
                            <p v-if="errors.newFileName" class="error-message">{{ errors.newFileName }}</p>
                        </div>

                        <div class="form-group" :class="{ 'has-error': errors.selectedFile }">
                            <label class="input-label">{{ $t('task.file') }}:</label>
                            <input
                                id="fileInput"
                                ref="fileInput"
                                type="file"
                                class="input"
                                @change="handleFileUpload"
                            />
                            <p v-if="errors.selectedFile" class="error-message">{{ errors.selectedFile }}</p>
                        </div>
                        <button
                            class="save-button at-btn crud__control-items__item at-btn--primary at-btn--large"
                            @click="saveFile"
                        >
                            {{ $t('task.save') }}
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <ul v-if="viewMode === 'grid'" class="grid-view">
            <p v-if="attachments.length === 0" class="no-files-message">{{ $t('task.noFiles') }}</p>
            <li
                v-for="file in attachments"
                :key="file.id"
                class="file-card"
                @mouseenter="setHover(file, true)"
                @mouseleave="setHover(file, false)"
            >
                <div class="file-preview">
                    <img v-if="file.preview" :src="file.preview" class="preview-image" @click="openPreview(file)" />
                    <span v-else>📄</span>
                </div>
                <p class="file-name" :title="file.title">
                    {{ file.title.length > 10 ? file.title.substring(0, 10) + '...' : file.title }}
                </p>
                <p class="file-date">{{ file.dateAdded }}</p>
                <div v-if="file.hover" class="file-actions">
                    <button v-if="file.preview" class="icon-button" @click="openPreview(file)">
                        <font-awesome-icon :icon="['fas', 'eye']" />
                    </button>
                    <button class="icon-button" @click="downloadFile(file)">
                        <font-awesome-icon :icon="['fas', 'download']" />
                    </button>
                    <button v-if="hasDelete" class="icon-button delete-button" @click="deleteFile(file.id)">
                        <font-awesome-icon :icon="['fas', 'trash']" />
                    </button>
                </div>
            </li>
        </ul>

        <ul v-else class="list-view">
            <li class="list-header">
                <span class="file-name">{{ $t('task.title') }}</span>
                <span class="file-size">{{ $t('task.size') }}</span>
                <span class="file-date">{{ $t('task.date') }}</span>
                <span class="actions">{{ $t('task.actions') }}</span>
            </li>
            <p v-if="attachments.length === 0" class="no-files-message">{{ $t('task.noFiles') }}</p>
            <li v-for="file in attachments" :key="file.id" class="list-item">
                <span class="file-name" :title="file.title">{{ file.title }}</span>
                <span class="file-size">{{ bytesToMB(file.size) }} MB</span>
                <span class="file-date">{{ file.dateAdded }}</span>
                <div class="actions">
                    <button v-if="file.preview" class="icon-button" @click="openPreview(file)">
                        <font-awesome-icon :icon="['fas', 'eye']" />
                    </button>
                    <button class="icon-button" @click="downloadFile(file)">
                        <font-awesome-icon :icon="['fas', 'download']" />
                    </button>
                    <button v-if="hasDelete" class="icon-button delete-button" @click="deleteFile(file.id)">
                        <font-awesome-icon :icon="['fas', 'trash']" />
                    </button>
                </div>
            </li>
        </ul>

        <div v-if="isPreviewOpen" class="preview-modal" @click="isPreviewOpen = false">
            <div class="preview-content" @click.stop>
                <button class="close-button" @click="isPreviewOpen = false">
                    <font-awesome-icon :icon="['fas', 'times']" />
                </button>
                <img v-if="selectedFile" :src="selectedFile.preview" class="preview-large" />
                <p class="file-name">{{ selectedFile?.name }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    import TaskFileService from '../services/task-file.service';
    import TaskPolicy from '@/policies/task.policy.js';
    import { mapGetters } from 'vuex';

    export default {
        props: {
            task: { type: Object, required: true },
            openForm: { type: Boolean, default: false },
        },
        data() {
            const baseUrl = process.env.VUE_APP_API_URL;

            return {
                viewMode: 'grid',

                isDropdownOpen: false,
                isPreviewOpen: false,
                isFileDropdownOpen: false,

                selectedFile: null,
                newFileName: null,

                attachments: [],
                errors: {},

                baseUrl,

                fileService: new TaskFileService(),
            };
        },
        computed: {
            ...mapGetters('user', ['user']),
            hasDelete() {
                return TaskPolicy.delete(this.user);
            },
        },
        mounted() {
            this.taskViewMode();
            this.assignedAttachments();
        },
        methods: {
            closeDropDowns() {
                if (!this.openForm) {
                    this.isDropdownOpen = false;
                    this.isFileDropdownOpen = false;
                }

                this.$emit('formClosed');
            },
            toggleDropdown() {
                this.isDropdownOpen = !this.isDropdownOpen;
            },
            toggleViewMode() {
                this.viewMode = this.viewMode === 'grid' ? 'table' : 'grid';
                localStorage.setItem('taskViewMode', JSON.stringify(this.viewMode));
                this.isDropdownOpen = false;
            },
            openFileDialog() {
                this.$refs.fileInput.click();
            },
            openPreview(file) {
                this.selectedFile = file;
                this.isPreviewOpen = true;
            },

            toggleFileDropdown() {
                this.isFileDropdownOpen = !this.isFileDropdownOpen;
            },
            handleFileUpload(event) {
                this.selectedFile = event.target.files[0];
            },
            validateForm() {
                this.errors = {};
                if (!this.newFileName || !this.newFileName.length > 2) {
                    this.errors.newFileName = 'File Title is required';
                }
                if (!this.selectedFile) {
                    this.errors.selectedFile = 'File is required';
                }
                return Object.keys(this.errors).length === 0;
            },
            saveFile() {
                if (!this.validateForm()) return;

                this.uploadFile();
                this.newFileName = '';
                this.selectedFile = null;
                this.isFileDropdownOpen = false;
            },

            async uploadFile() {
                const formData = new FormData();
                formData.append('task_file[file]', this.selectedFile);
                formData.append('task_file[title]', this.newFileName);
                formData.append('task_file[task_id]', this.task.id);

                try {
                    const { data } = await this.fileService.save(formData);
                    const file = data.data.attributes;

                    this.attachments.push({
                        id: file.id,
                        title: file.title,
                        size: file.size,
                        dateAdded: this.getAddedDate(file),
                        preview: this.getPreviewUrl(file),
                    });

                    this.successSaveMessage();
                } catch (error) {
                    console.error('Upload failed', error);
                }
            },

            async downloadFile(file) {
                try {
                    await this.fileService.download(file.id);
                } catch (error) {
                    console.error('Download failed', error);
                }
            },

            async bulkDownload() {
                const fileIds = this.attachments.map(file => file.id);
                if (fileIds.length === 0) return;

                const formData = new FormData();

                fileIds.forEach(id => {
                    formData.append('task_file[ids][]', id);
                });

                try {
                    await this.fileService.bulkDownload(formData, this.task.ref);
                } catch (error) {
                    console.error('Bulk download failed', error);
                }
            },
            async deleteFile(id) {
                try {
                    await this.fileService.delete(id);

                    this.attachments = this.attachments.filter(file => file.id !== id);

                    this.successDeleteMessage();
                } catch (error) {
                    console.log('Error destroy File', error);
                }
            },

            setHover(file, value) {
                this.$set(file, 'hover', value);
            },
            assignedAttachments() {
                this.attachments = this.task.files
                    .map(file => ({
                        ...file,
                        preview: this.getPreviewUrl(file),
                        dateAdded: this.getAddedDate(file),
                    }))
                    .sort((a, b) => a.title.localeCompare(b.title));
            },

            getPreviewUrl(file) {
                return `${this.baseUrl}${file.file_url}`;
            },
            getAddedDate(file) {
                const date = new Date(file.created_at);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = date.getFullYear();
                return `${day}-${month}-${year}`;
            },

            bytesToMB(bytes) {
                const MB = bytes / (1024 * 1024);
                return Math.round(MB * 100) / 100;
            },

            taskViewMode() {
                const taskViewMode = JSON.parse(localStorage.getItem('taskViewMode'));

                if (taskViewMode) {
                    this.viewMode = taskViewMode;
                }
            },

            successDeleteMessage() {
                this.$Notify({
                    type: 'success',
                    title: this.$t('notification.record.delete.success.title'),
                    message: this.$t('notification.record.delete.success.message'),
                });
            },

            successSaveMessage() {
                this.$Notify({
                    type: 'success',
                    title: this.$t('notification.save.success.title'),
                    message: this.$t('notification.save.success.message'),
                });
            },
        },
        watch: {
            openForm(newVal) {
                if (newVal) {
                    this.isFileDropdownOpen = true;
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    .attachments {
        background: #fff;
        padding: 1rem;
        border-radius: 0.5rem;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        border: 1px solid #e5e7eb;

        .theme-dark & {
            background-color: #333;
            border-color: #555;
        }
    }

    .attachments-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 2px solid #f3f4f6;
        padding-bottom: 0.5rem;
        margin-bottom: 1rem;

        .theme-dark & {
            background-color: #333;
            border-color: #555;
        }
    }

    .header-actions {
        display: flex;
        gap: 10px;
    }

    .dropdown {
        position: relative;
    }

    .dropdown-menu {
        position: absolute;
        top: 100%;
        right: 0;
        background: white;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        border-radius: 0.375rem;
        overflow: hidden;
        z-index: 10;
        min-width: max-content;
        white-space: nowrap;

        .theme-dark & {
            background-color: #333;
        }
    }

    .dropdown-menu button {
        display: flex;
        width: 100%;
        padding: 0.5rem 1rem;
        border: none;
        background: none;
        text-align: left;
        cursor: pointer;
        justify-content: space-between;
    }

    .dropdown-menu button:hover {
        background: #f3f4f6;

        .theme-dark & {
            color: #c4c4cf;
            background-color: #555;
        }
    }

    .icon-button {
        border: none;
        background: transparent;
        cursor: pointer;
        font-size: 1.2rem;
        color: #6b7280;

        &:hover {
            color: #111827;
        }

        .theme-dark & {
            color: #ffa500;

            &:hover {
                color: #c4c4cf;
            }
        }
    }

    .grid-view {
        display: flex;
        gap: 1rem;
        flex-wrap: wrap;
    }

    .file-card {
        background: #f9fafb;
        padding: 1rem;
        border-radius: 0.5rem;
        text-align: center;
        width: 120px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);

        .theme-dark & {
            background-color: #333;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
        }
    }

    .file-preview {
        font-size: 2rem;
        margin-bottom: 0.5rem;
    }

    .actions {
        display: flex;
        gap: 20px;
    }

    .file-card {
        position: relative;
        transition: box-shadow 0.2s;
    }

    .file-card:hover {
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.4);
    }

    .file-actions {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        gap: 20px;
        background: rgba(255, 255, 255, 0.9);
        padding: 5px;
        border-radius: 5px;

        .theme-dark & {
            background-color: #333;
            opacity: 0.9;
        }
    }

    .preview-image {
        width: 100%;
        height: auto;
        max-height: 100px;
        object-fit: cover;
        border-radius: 5px;
    }

    .preview-modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 1000;
    }

    .preview-content {
        background: white;
        padding: 1rem;
        border-radius: 0.5rem;
        text-align: center;
        max-width: 90%;
        max-height: 90%;
        position: relative;
    }

    .preview-large {
        max-width: 100%;
        max-height: 80vh;
        border-radius: 0.5rem;
    }

    .close-button {
        position: absolute;
        top: 10px;
        right: 10px;
        background: none;
        border: none;
        font-size: 1.5rem;
        cursor: pointer;
    }

    .file-item:hover {
        background-color: #f3f4f6;
    }

    .list-view {
        width: 100%;
        border: 1px solid #e5e7eb;
        border-radius: 0.5rem;
        overflow: hidden;

        .theme-dark & {
            border-color: #555;
        }
    }

    .list-header,
    .list-item {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr;
        align-items: center;
        padding: 0.75rem 1rem;
        gap: 10px;
        text-align: left;
    }

    .list-header {
        background: #f3f4f6;
        font-weight: bold;
        border-bottom: 2px solid #d1d5db;

        .theme-dark & {
            background: #444;
            border-color: #555;
        }
    }

    .list-item {
        border-bottom: 1px solid #e5e7eb;
        .theme-dark & {
            border-color: #555;
        }
    }

    .list-header > span,
    .list-item > span {
        flex: 1;
        padding: 0 0.5rem;
        text-overflow: ellipsis;
        overflow: hidden;
        text-align: left;
    }

    .actions {
        display: flex;
        gap: 10px;
        justify-content: flex-end;
        min-width: 120px;
    }

    .attachment-count {
        background-color: #d1d5db;
        color: #4b5563;
        font-size: 12px;
        font-weight: 600;
        border-radius: 10px;
        padding: 2px 8px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        min-width: 24px;

        .theme-dark & {
            background-color: #555;
            color: #ffa500;
        }
    }

    .dropdown-container {
        position: relative;
        display: inline-block;
    }

    .dropdown-form {
        position: absolute;
        top: 100%;
        left: 60%;
        transform: translateX(-60%);
        background: white;
        padding: 10px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        width: max-content;
        min-width: 150px;
        z-index: 10;

        .theme-dark & {
            background-color: #333;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
        }
    }

    .input {
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        width: 100%;
        transition: border-color 0.3s, box-shadow 0.3s;

        .theme-dark & {
            background-color: #333;
            border-color: #555;
            color: #ffa500;
        }

        &:focus {
            border-color: #176bda;
            outline: none;
            box-shadow: 0 0 10px rgba(0, 50, 212, 0.2);
            .theme-dark & {
                border-color: #ffa500;
                box-shadow: 0 0 10px rgba(255, 165, 0, 0.4);
            }
        }
    }

    .input-label {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 5px;
    }

    .no-files-message {
        text-align: center;
        font-size: 16px;
        color: #666;
        margin-top: 20px;
    }

    .error-message {
        color: #e74c3c;
        font-size: 12px;
        margin-top: 5px;

        .theme-dark & {
            color: #ff4c4c;
        }
    }

    .form-group.has-error input,
    .form-group.has-error textarea,
    .form-group.has-error select {
        border-color: #e74c3c;

        .theme-dark & {
            border-color: #ff4c4c;
        }
    }
</style>
