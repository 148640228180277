<template>
    <div class="page-container">
        <div class="page-header">
            <h1>{{ $t('tasks.grid-title') }}</h1>
        </div>
        <div class="tasks-container">
            <div class="tasks-header">
                <at-input
                    v-model="searchQuery"
                    type="text"
                    :placeholder="$t('tasks.search_placeholder')"
                    class="search-input"
                />
                <div class="header-buttons">
                    <button
                        class="filter-button at-btn crud__control-items__item at-btn--primary at-btn--large"
                        @click="toggleFilters"
                    >
                        {{ $t('tasks.filters') }}
                    </button>
                    <button
                        v-if="hasCreate"
                        class="create-button at-btn crud__control-items__item at-btn--primary at-btn--large"
                        @click="$router.push({ path: '/tasks/new' })"
                    >
                        <i class="at-btn__icon icon icon-edit"></i>
                        {{ $t('tasks.create_task') }}
                    </button>
                </div>
                <task-filter :visible="filterModalVisible" @apply-filters="handleFiltersChange" @close="closeFilters" />
            </div>

            <ul v-if="tasks.length" class="tasks-list">
                <li class="task-row header-row">
                    <div class="task-cell task-name">{{ $t('tasks.task_table.name') }}</div>
                    <div class="task-cell task-ref">{{ $t('tasks.task_table.ref') }}</div>
                    <div class="task-cell task-users">{{ $t('tasks.task_table.users') }}</div>
                    <div class="task-cell task-status">{{ $t('tasks.task_table.status') }}</div>
                    <div class="task-cell task-actions">{{ $t('tasks.task_table.actions') }}</div>
                </li>
                <li v-for="task in tasks" :key="task.attributes.id" class="task-row">
                    <div class="task-cell task-name">{{ task.attributes.task_name }}</div>
                    <div class="task-cell task-ref">{{ task.attributes.ref }}</div>
                    <div class="task-cell task-users">
                        <div v-for="user in task.attributes.users.slice(0, 1)" :key="user.attributes.id">
                            <at-tooltip
                                :content="`${user.attributes.first_name} ${user.attributes.last_name}`"
                                :placement="'top'"
                            >
                                <user-avatar :user="user.attributes" />
                            </at-tooltip>
                        </div>
                        <span
                            v-if="task.attributes.users.length > 1"
                            class="more-users"
                            @mouseenter="showTooltip($event, task.attributes.users.slice(1))"
                            @mouseleave="hideTooltip"
                        >
                            +{{ task.attributes.users.length - 1 }} more
                        </span>
                    </div>
                    <div class="task-cell task-status">{{ task.attributes.status.name }}</div>
                    <div class="task-cell task-actions">
                        <button
                            class="task-action show-button at-btn at-btn--primary at-btn--large action-button"
                            @click="$router.push({ path: `/tasks/view/${task.attributes.id}` })"
                        >
                            <font-awesome-icon :icon="['fas', 'eye']" />
                            {{ $t('tasks.show') }}
                        </button>
                        <button
                            v-if="hasDelete"
                            class="task-action delete-button at-btn at-btn--error at-btn--large action-button"
                            @click="deleteTask(task.attributes.id)"
                        >
                            <font-awesome-icon :icon="['fas', 'trash']" />
                            {{ $t('tasks.delete') }}
                        </button>
                    </div>
                </li>
            </ul>

            <div v-else class="no-tasks-message">
                {{ $t('tasks.no_tasks') }}
            </div>

            <div v-if="tooltip.visible" class="tooltip" :style="tooltip.position">
                <div v-for="user in tooltip.users" :key="user.attributes.id" class="tooltip-user">
                    <user-avatar :user="user.attributes" />
                    {{ `${user.attributes.first_name} ${user.attributes.last_name}` }}
                </div>
            </div>
        </div>

        <div v-if="tasks && tasks.length" class="pagination">
            <at-pagination :total="totalItems" :current="page" :page-size="itemsPerPage" @page-change="onPageChange">
                <template #previous-text>{{ $t('pagination.previous') }}</template>
                <template #next-text>{{ $t('pagination.next') }}</template>
            </at-pagination>
        </div>
        <delete-modal
            :visible="deleteModalVisible"
            :title="$t('delete_modal.title')"
            :message="$t('delete_modal.message')"
            @confirm="confirmDelete"
            @close="closeDeleteModal"
        />
    </div>
</template>

<script>
    import { debounce } from 'lodash';
    import UserAvatar from '../../../../components/UserAvatar.vue';
    import TasksService from '../../../../services/resource/task.service';
    import TaskFilter from '../../components/TaskFilter.vue';
    import DeleteModal from '../../../../components/DeleteModal.vue';
    import TaskPolicy from '@/policies/task.policy.js';
    import { mapGetters } from 'vuex';

    export default {
        components: { UserAvatar, TaskFilter, DeleteModal },
        data() {
            return {
                tasks: [],
                searchQuery: '',
                previousQuery: '',
                tooltip: {
                    visible: false,
                    users: [],
                    position: {},
                    timeout: null,
                },
                service: new TasksService(),

                filters: {
                    projects: [],
                    statuses: [],
                    users: [],
                },
                filterModalVisible: false,

                page: +(this.$route.query.page || 1),
                totalItems: 0,
                itemsPerPage: 15,
                queryParams: {
                    page: this.$route.query.page,
                },

                deleteModalVisible: false,
                deleteTaskId: null,
            };
        },
        computed: {
            ...mapGetters('user', ['user']),
            hasCreate() {
                return TaskPolicy.create(this.user);
            },
            hasDelete() {
                return TaskPolicy.delete(this.user);
            },
        },
        async mounted() {
            await this.fetchTasks();
        },
        methods: {
            toggleFilters() {
                this.filterModalVisible = !this.filterModalVisible;
            },
            closeFilters() {
                this.filterModalVisible = false;
            },
            handleFiltersChange: debounce(function (filters) {
                this.filters = filters;
                this.fetchTasks();
            }, 300),
            closeDeleteModal() {
                this.deleteModalVisible = false;
            },
            deleteTask(taskId) {
                this.deleteTaskId = taskId;
                this.deleteModalVisible = true;
            },

            confirmDelete() {
                this.service.deleteItem(this.deleteTaskId).then(() => {
                    this.deleteModalVisible = false;
                    this.deleteTaskId = null;

                    this.$Notify({
                        type: 'success',
                        title: this.$t('notification.record.delete.success.title'),
                        message: this.$t('notification.record.delete.success.message'),
                    });
                    this.fetchTasks();
                });
            },
            async fetchTasks() {
                const queryParams = {
                    page: this.page,
                    search: { query: this.searchQuery },
                    project_ids: this.filters.projects,
                    status_ids: this.filters.statuses,
                    user_ids: this.filters.users,
                };
                try {
                    const res = await this.service.getWithFilters(queryParams);

                    const { data, pagination } = res.data;
                    this.totalItems = pagination.total;
                    this.page = pagination.page;
                    this.itemsPerPage = 1;
                    this.tasks = data;
                } catch ({ response }) {
                    if (process.env.NODE_ENV === 'development') {
                        console.warn(response ? response : 'request is canceled');
                    }
                }
            },

            async onPageChange(page) {
                if (this.queryParams.page === page) return;
                this.queryParams.page = page;
                this.page = page;
                await this.fetchTasks();
            },

            showTooltip(event, users) {
                const tooltipWidth = 200;
                const offsetX = 10;
                const offsetY = 10;
                clearTimeout(this.tooltip.timeout);
                this.tooltip.timeout = setTimeout(() => {
                    this.tooltip.users = users;
                    this.tooltip.position = {
                        top: `${event.clientY + offsetY}px`,
                        left: `${Math.min(event.clientX + offsetX, window.innerWidth - tooltipWidth)}px`,
                        width: `${tooltipWidth}px`,
                    };
                    this.tooltip.visible = true;
                }, 300);
            },
            hideTooltip() {
                clearTimeout(this.tooltip.timeout);
                this.tooltip.visible = false;
            },
        },

        watch: {
            searchQuery: debounce(function () {
                this.fetchTasks();
            }, 350),
        },
    };
</script>

<style scoped lang="scss">
    @import '../../styles/actions/List.scss';
</style>
