<template>
    <div class="intervals-container">
        <div class="header">
            <h1>Intervals</h1>
            <div class="total-time">Total: {{ totalDuration }}</div>
        </div>

        <div v-if="intervals.length > 0" class="interval-list">
            <div v-for="interval in intervals" :key="interval.id" class="interval-item">
                <div class="description">
                    {{ interval.attributes.description }}
                </div>
                <div class="tags">
                    <at-tooltip v-for="tag in interval.attributes.tags" :key="tag.id" :content="tag.attributes.name">
                        <font-awesome-icon
                            :icon="['fas', 'tag']"
                            class="tag-icon"
                            :style="{ color: tag.attributes.color }"
                        />
                    </at-tooltip>
                </div>

                <div class="user-info">
                    <img :src="userAvatarUrl(interval.attributes.user)" alt="User Avatar" class="avatar" />
                    <span class="user-name">{{
                        `${interval.attributes.user.attributes.first_name} ${interval.attributes.user.attributes.last_name}`
                    }}</span>
                </div>
                <div class="time-range">
                    <at-tooltip :content="'Time range'">
                        {{ formatTimeRange(interval.attributes.start_at, interval.attributes.end_at) }}
                    </at-tooltip>
                </div>
                <div class="duration">
                    <at-tooltip :content="'Duration'">
                        {{ formatSpentTime(interval.attributes.duration) }}
                    </at-tooltip>
                </div>
            </div>
        </div>

        <div v-else class="empty-state">
            <p>{{ $t('intervals.noIntervals') }}</p>
        </div>

        <div v-if="intervals.length > 0" class="pagination">
            <at-pagination
                :total="totalItems"
                :current="page"
                :page-size="itemsPerPage"
                class="crud__pagination"
                @page-change="handlePageChange"
            >
                <template #previous-text>{{ $t('pagination.previous') }}</template>
                <template #next-text>{{ $t('pagination.next') }}</template>
            </at-pagination>
        </div>
    </div>
</template>

<script>
    import DashboardService from '../../Dashboard/services/dashboard.service';
    export default {
        props: { task: { type: Object, required: true } },
        data() {
            return {
                intervals: [],

                page: +(this.$route.query.page || 1),
                totalItems: 0,
                itemsPerPage: 10,

                queryParams: {
                    page: this.$route.query.page,
                },

                service: new DashboardService(),
            };
        },
        computed: {
            totalDuration() {
                const seconds = this.task.total_spent_time;

                return this.formatSpentTime(seconds);
            },
        },
        async mounted() {
            await this.fetchTimeIntervals();
        },
        methods: {
            userAvatarUrl(user) {
                return `${process.env.VUE_APP_API_URL}/${user.attributes.avatar_url}`;
            },
            handlePageChange(page) {
                if (this.queryParams.page === page) {
                    return;
                }
                this.queryParams.page = page;
                return this.fetchTimeIntervals();
            },

            async fetchTimeIntervals() {
                const formData = new FormData();
                formData.append('task_id', this.task.id);
                if (this.queryParams.page) {
                    formData.append('page', this.queryParams.page);
                }

                try {
                    const res = await this.service.loadTimes(formData);

                    const { data, pagination } = res.data;
                    this.totalItems = pagination.total;
                    this.page = pagination.page;
                    this.itemsPerPage = 1;

                    this.intervals = data;
                } catch (error) {
                    console.log('Error time-intervals request', error);
                }
            },

            formatSpentTime(seconds) {
                const hrs = Math.floor(seconds / 3600);
                const mins = Math.floor((seconds % 3600) / 60);
                const secs = seconds % 60;

                return `${hrs}h ${mins.toString().padStart(2, '0')}m ${secs.toString().padStart(2, '0')}s`;
            },

            formatTimeRange(start, end) {
                if (!start || !end) {
                    return '00:00:00 - 00:00:00';
                }

                const formatTime = date => {
                    const d = new Date(date);
                    if (isNaN(d.getTime())) return '00:00:00';

                    return `${String(d.getHours()).padStart(2, '0')}:${String(d.getMinutes()).padStart(
                        2,
                        '0',
                    )}:${String(d.getSeconds()).padStart(2, '0')}`;
                };

                return `${formatTime(start)} - ${formatTime(end)}`;
            },
        },
    };
</script>

<style scoped lang="scss">
    .empty-state {
        text-align: center;
        font-size: 16px;
        color: #888;
        padding: 20px;
    }

    .intervals-container {
        width: 100%;
        background: #f9f9f9;
        padding: 16px;
        border-radius: 8px;
        border: 1px solid #e5e7eb;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);

        .theme-dark & {
            background-color: #333;
            border-color: #555;
        }
    }

    .pagination {
        margin: 15px 0 0 0;
        display: flex;
        justify-content: flex-end;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 16px;
    }

    .total-time {
        font-weight: bold;
    }

    .interval-list {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .interval-item {
        display: grid;
        grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
        align-items: center;
        background: white;
        border: 1px solid #e5e7eb;
        padding: 12px;
        border-radius: 6px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        gap: 10px;

        .theme-dark & {
            background-color: #333;
            border-color: #555;
        }
    }

    .description {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
    }

    .tags {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
    }

    .tag-icon {
        font-size: 16px;
    }

    .user-info {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    .duration {
        display: flex;
        justify-content: flex-end;
    }

    .time-range {
        display: flex;
        justify-content: flex-end;
    }

    .avatar {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
    }

    .user-name {
        font-weight: bold;
    }

    @media (max-width: 1024px) {
        .interval-item {
            grid-template-columns: 2fr 1fr 1fr;
            font-size: 14px;
        }

        .avatar {
            width: 30px;
            height: 30px;
        }
    }

    @media (max-width: 768px) {
        .interval-item {
            grid-template-columns: 1fr;
            gap: 6px;
            padding: 10px;
        }

        .tags {
            flex-wrap: nowrap;
            overflow-x: auto;
        }

        .user-info {
            flex-direction: row;
            gap: 5px;
        }

        .time-range,
        .duration {
            text-align: left;
        }
    }

    @media (max-width: 480px) {
        .description {
            max-width: 100%;
            white-space: normal;
        }

        .user-name {
            display: none;
        }

        .tags {
            display: none;
        }

        .total-time {
            font-size: 12px;
        }
    }
</style>
