<template>
    <div v-if="project" class="task-view">
        <div class="top-bar">
            <div class="header-left-section">
                <span class="task-color" :style="{ backgroundColor: project.color }"></span>
                <p class="project-ref" style="cursor: pointer" @click="goToKanban(project)">
                    {{ project.shortcut }}
                    <font-awesome-icon :icon="['fas', 'link']" />
                </p>
            </div>
            <div v-click-outside="closeMoreActionsDropDown" class="top-actions">
                <button class="icon-button" @click="moreActionsDropdown = !moreActionsDropdown">
                    <font-awesome-icon :icon="['fas', 'ellipsis']" />
                </button>

                <div v-if="moreActionsDropdown" class="more-actions-menu">
                    <div class="dropdown-section">
                        <button class="dropdown-item" @click="toggleColorPicker">
                            <font-awesome-icon :icon="['fas', 'palette']" />
                            Change Color
                        </button>

                        <div v-if="showColorPickerDropdown" class="color-picker-dropdown">
                            <label class="dropdown-label">Select New Color:</label>
                            <chrome-picker :value="selectedColor" @input="selectedColor = $event" />

                            <div class="color-actions">
                                <button
                                    class="save-name-btn at-btn crud__control-items__item at-btn--primary at-btn--primary at-btn--large"
                                    @click="saveColor"
                                >
                                    <font-awesome-icon :icon="['fas', 'check']" />
                                </button>
                                <button
                                    class="cancel-name-btn at-btn at-btn--error at-btn--large action-button"
                                    @click="closeColorPicker"
                                >
                                    <font-awesome-icon :icon="['fas', 'xmark']" />
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="delete-section">
                        <div class="dropdown-separator"></div>

                        <button class="dropdown-item destroy-task-btn" @click="deleteProject()">
                            <font-awesome-icon :icon="['fas', 'trash']" />
                            {{ $t('task.delete') }}
                        </button>
                    </div>
                </div>

                <button class="back-button" @click="$router.go(-1)">{{ $t('task.back') }}</button>
            </div>
        </div>

        <div class="content">
            <div class="task-details">
                <div class="view-header">
                    <div v-if="!isEditingName" class="task-title" @click="editProjectName">
                        <h1>{{ project.name }}</h1>
                    </div>
                    <div v-else class="edit-task-name">
                        <input
                            ref="projectNameInput"
                            v-model="editedProjectName"
                            class="input task-name-input"
                            @keyup.enter="saveProjectName"
                            @keyup.esc="cancelEditProjectName"
                        />
                        <div class="edit-task-name-buttons">
                            <button
                                class="save-name-btn at-btn crud__control-items__item at-btn--primary at-btn--primary at-btn--large"
                                @click="saveProjectName"
                            >
                                <font-awesome-icon :icon="['fas', 'check']" />
                            </button>
                            <button
                                class="cancel-name-btn at-btn at-btn--error at-btn--large action-button"
                                @click="cancelEditProjectName"
                            >
                                <font-awesome-icon :icon="['fas', 'xmark']" />
                            </button>
                        </div>
                    </div>
                </div>

                <!-- <div class="header-button-section">
                    <button v-click-outside="closeDropDown" class="add-button" @click="toggleDropdown">
                        + {{ $t('task.add') }}
                    </button>
                    <div v-if="showDropdown" class="dropdown-menu">
                        <button class="dropdown-item" @click="attachFile">
                            <font-awesome-icon :icon="['fas', 'paperclip']" />
                            {{ $t('task.attach_file') }}
                        </button>
                        <button class="dropdown-item" @click="addLink">
                            <font-awesome-icon :icon="['fas', 'link']" />
                            {{ $t('task.add_link') }}
                        </button>
                    </div>
                </div> -->

                <div class="left-section">
                    <label for="description" class="section-title">{{ $t('task.description') }}</label>

                    <div v-if="!isEditing" class="description-view" @click="editDescription">
                        {{ project.description || $t('task.click_to_add_description') }}
                    </div>

                    <div v-else>
                        <at-textarea v-model="editedDescription" class="editor-textarea"></at-textarea>
                        <div class="editor-buttons">
                            <button
                                class="save-button at-btn crud__control-items__item at-btn--primary at-btn--large"
                                @click="saveDescription"
                            >
                                {{ $t('task.save') }}
                            </button>
                            <button class="cancel-button" @click="closeEditDescription">{{ $t('task.cancel') }}</button>
                        </div>
                    </div>
                </div>

                <div class="left-section">
                    <project-activity :project="project" />
                </div>
            </div>

            <!-- Right Column -->
            <div class="task-sidebar">
                <project-details-bar :project="project" />
            </div>
        </div>

        <delete-modal
            :visible="deleteModalVisible"
            :title="$t('delete_modal.title')"
            :message="$t('delete_modal.message')"
            @confirm="confirmDelete"
            @close="closeDeleteModal"
        />
    </div>
</template>

<script>
    import ProjectDetailsBar from '../../components/ProjectDetailsBar.vue';
    import ProjectActivity from '../../components/ProjectActivity.vue';
    import ProjectService from '@/services/resource/project.service';
    import DeleteModal from '../../../../components/DeleteModal.vue';

    import { Chrome } from 'vue-color';
    import { mapGetters } from 'vuex';

    let reconnectInterval = 5000;
    let maxRetries = 10;
    let retries = 0;

    export default {
        components: { ProjectDetailsBar, ProjectActivity, chromePicker: Chrome, DeleteModal },
        data() {
            return {
                showDropdown: false,
                moreActionsDropdown: false,
                showColorPickerDropdown: false,

                isEditing: false,
                isEditingName: false,

                deleteModalVisible: false,

                editedProjectName: '',
                editedDescription: '',
                selectedColor: '',

                project: null,

                projectService: new ProjectService(),

                wsUrl: process.env.VUE_APP_WS_URL || '',
                ws: null,
            };
        },
        computed: {},
        async created() {
            await this.fetchProject();
            this.connectWebSocket();
        },
        methods: {
            closeMoreActionsDropDown() {
                this.moreActionsDropdown = false;
                this.showColorPickerDropdown = false;
            },

            closeColorPicker() {
                this.showColorPickerDropdown = false;
            },

            deleteProject() {
                this.deleteModalVisible = true;
            },

            closeDeleteModal() {
                this.deleteModalVisible = false;
            },

            goToKanban(project) {
                this.$router.push({ name: 'kanban.board', query: { project_id: project.id } });
            },

            toggleColorPicker() {
                this.showColorPickerDropdown = !this.showColorPickerDropdown;
            },

            editProjectName() {
                // if (!this.hasUpdate) return;
                this.isEditingName = true;
                this.editedProjectName = this.project.name;
                this.$nextTick(() => {
                    this.$refs.projectNameInput.focus();
                });
            },
            saveProjectName() {
                const formData = new FormData();
                formData.append('project[name]', this.editedProjectName);

                this.updateProjectRequest(formData);

                this.isEditingName = false;
            },
            cancelEditProjectName() {
                this.isEditingName = false;
                this.editedProjectName = this.project.name;
            },

            editDescription() {
                // if (!this.hasUpdate) return;

                this.editedDescription = this.project.description;
                this.isEditing = true;
            },
            closeEditDescription() {
                this.isEditing = false;
            },

            saveDescription() {
                const formData = new FormData();
                formData.append('project[description]', this.editedDescription);

                this.updateProjectRequest(formData);

                this.isEditing = false;
            },

            confirmDelete() {
                this.projectService.deleteItem(this.project.id).then(() => {
                    this.deleteModalVisible = false;
                    this.$Notify({
                        type: 'success',
                        title: this.$t('notification.record.delete.success.title'),
                        message: this.$t('notification.record.delete.success.message'),
                    });
                    this.$router.push({ name: 'project.list' });
                });
            },

            async saveColor() {
                if (this.selectedColor !== this.project.color) {
                    try {
                        const formData = new FormData();
                        formData.append('project[color]', this.selectedColor.hex);

                        await this.projectService.update(formData, this.project.id);

                        this.$Notify({
                            type: 'success',
                            title: this.$t('notification.save.success.title'),
                            message: 'Color updated successfully!',
                        });
                    } catch (error) {
                        console.error('Error Update color', error);
                    }
                }
                this.closeMoreActionsDropDown();
            },

            async updateProjectRequest(formData) {
                const id = this.project.id;
                try {
                    this.projectService.update(formData, id);
                } catch (error) {
                    console.log('Error update project request', error);
                }
            },

            async fetchProject() {
                const projectId = this.$route.params.id;

                try {
                    const { data } = await this.projectService.getItem(projectId);

                    this.project = data.data.attributes;
                } catch (error) {
                    console.log('Error show project request', error);
                }
            },

            connectWebSocket() {
                if (!this.wsUrl || !this.project) return;

                if (this.ws && this.ws.readyState !== WebSocket.CLOSED) {
                    console.warn('WebSocket connection already exists');
                    return;
                }

                this.ws = new WebSocket(this.wsUrl);

                this.ws.onopen = () => {
                    retries = 0;

                    const subProject = {
                        command: 'subscribe',
                        identifier: JSON.stringify({
                            channel: 'ProjectChannel',
                            project_id: this.project.id,
                        }),
                    };

                    this.ws.send(JSON.stringify(subProject));
                };

                this.ws.onmessage = event => {
                    const data = JSON.parse(event.data);
                    if (data.type !== 'ping' && data.message) {
                        const { action, project } = data.message;

                        const actions = {
                            new_logo: () => this.wsNewLogo(project),
                            remove_logo: () => this.wsRemoveLogo(project),
                            update_logo: () => this.wsUpdateProject(project),
                        };

                        if (actions[action]) {
                            actions[action]();
                        } else {
                            console.warn(`Unhandled action: ${action}`);
                        }
                    }
                };

                this.ws.onclose = () => {
                    if (process.env.NODE_ENV === 'development') {
                        console.warn('WebSocket connection closed');
                    }
                    this.retryWebSocketConnection();
                };

                this.ws.onerror = error => {
                    this.retryWebSocketConnection();
                };
            },
            retryWebSocketConnection() {
                if (retries < maxRetries) {
                    retries += 1;
                    setTimeout(() => {
                        this.connectWebSocket();
                    }, reconnectInterval);
                } else {
                    if (process.env.NODE_ENV === 'development') {
                        console.warn('The maximum number of reconnections has been reached. ProjectChannel');
                    }
                }
            },

            wsNewLogo(params) {
                this.project = params.data.attributes;
            },

            wsRemoveLogo(params) {
                this.project = params.data.attributes;
            },

            wsUpdateProject(params) {
                this.project = params.data.attributes;
            },
        },
    };
</script>

<style scoped lang="scss">
    @import '../../styles/actions/View.scss';
</style>
