<template>
    <div class="task-details">
        <!-- Status Dropdown -->
        <div class="dropdown-container">
            <button class="dropdown-button" @click="toggleStatusDropdown">
                {{ task.status.name }}
                <font-awesome-icon :icon="['fas', 'chevron-down']" />
            </button>
            <ul v-if="statusDropdownOpen" class="details-content">
                <li
                    v-for="status in statuses"
                    :key="status.attributes.id"
                    class="status-item"
                    @click="updateStatus(status)"
                >
                    {{ status.attributes.name }}
                </li>
            </ul>
        </div>

        <!-- Details Dropdown -->
        <div class="dropdown-container">
            <button class="dropdown-button" @click="toggleDetailsDropdown">
                {{ $t('task.details') }}
                <font-awesome-icon :icon="['fas', detailsDropdownOpen ? 'chevron-up' : 'chevron-down']" />
            </button>
            <div v-if="detailsDropdownOpen" class="details-content">
                <!-- Users -->
                <div v-click-outside="closeUsersDropdown" class="info-row">
                    <label>{{ $t('task.users') }}:</label>

                    <div class="assignees">
                        <div class="assigned-users-container">
                            <div v-for="user in assignedUsers" :key="user.id" class="assigned-user">
                                <at-tooltip :content="`${user.first_name} ${user.last_name}`">
                                    <user-avatar :user="user" class="avatar" />
                                </at-tooltip>
                            </div>
                        </div>

                        <button v-if="hasUpdate" class="add-user" @click="toggleUsersDropdown">+</button>

                        <div v-if="usersDropdownOpen" class="users-dropdown">
                            <input
                                v-model="searchQuery"
                                type="text"
                                :placeholder="$t('task.searchUser')"
                                class="search-input"
                            />
                            <div v-for="user in filteredUsers" :key="user.id" class="dropdown-item">
                                <div class="user-item">
                                    <input
                                        v-model="assignedUsersIds"
                                        type="checkbox"
                                        :value="user.id"
                                        class="custom-checkbox"
                                        @change="handleUserChange"
                                    />
                                    <user-avatar :user="user" class="avatar" />
                                    <span class="user-name">{{ `${user.first_name} ${user.last_name}` }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Tags -->
                <div v-click-outside="closeTagsDropdown" class="info-row">
                    <label>{{ $t('task.tags') }}:</label>
                    <div class="assignees">
                        <div class="selected-tags">
                            <at-tooltip></at-tooltip>
                            <div
                                v-for="tag in assignedTags"
                                :key="tag.attributes.id"
                                class="tag"
                                :style="{ backgroundColor: tag.attributes.color }"
                                :title="tag.attributes.name"
                            >
                                <at-tooltip :content="tag.attributes.name">
                                    <font-awesome-icon :icon="['fas', 'tag']" />
                                </at-tooltip>
                            </div>
                        </div>

                        <button v-if="hasUpdate" class="add-tag" @click="toggleTagsDropdown">+</button>

                        <div v-if="tagsDropdownOpen" class="tags-dropdown">
                            <input
                                v-model="searchQuery"
                                type="text"
                                :placeholder="$t('task.searchTag')"
                                class="search-input"
                            />
                            <div v-for="tag in filteredTags" :key="tag.attributes.id" class="dropdown-item">
                                <div class="tag-item">
                                    <input
                                        v-model="assignedTagsIds"
                                        type="checkbox"
                                        :value="tag.attributes.id"
                                        class="custom-checkbox"
                                    />
                                    <span class="tag-color" :style="{ backgroundColor: tag.attributes.color }"></span>
                                    <span class="tag-name">{{ tag.attributes.name }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Sprint -->
                <div class="info-row">
                    <label>{{ $t('task.sprint') }}:</label>
                    <sprints-dropdown :task="task" />
                </div>

                <!-- Story Points -->
                <div v-if="task.sprint" class="info-row">
                    <label>{{ $t('task.storyPoints') }}:</label>
                    <StoryPointsInput :task="task" />
                </div>

                <!-- Deadline -->
                <div class="info-row">
                    <label>{{ $t('task.deadline') }}:</label>
                    <div v-click-outside="closeDeadlineEditor" class="deadline-data">
                        <template v-if="!isEditing">
                            <span v-if="task.deadline" @click="openEditor">
                                {{ formatDate(task.deadline) }}
                            </span>
                            <span v-else @click="openEditor">--/--/--</span>
                        </template>

                        <date-picker
                            v-if="isEditing"
                            ref="datePicker"
                            v-model="selectedDate"
                            :initialDate="task.deadline"
                            @close="isEditing = false"
                            @input="updateDeadline"
                        />
                    </div>
                </div>
            </div>
        </div>

        <div class="dropdown-separator"></div>

        <!-- Footer -->
        <div class="footer">
            <div class="footer-left">
                <div class="spent-time">
                    <strong>{{ $t('task.spent-time') }}:</strong>
                    {{ formatSpentTime(task.total_spent_time) }}
                </div>
            </div>
            <div class="footer-right">
                <div>
                    <strong>{{ $t('task.created') }}:</strong> {{ formatDate(task.created_at) }}
                </div>
                <div>
                    <strong>{{ $t('task.updated') }}:</strong> {{ formatDate(task.updated_at) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import UserAvatar from '@/components/UserAvatar.vue';
    import StatusService from '../../Statuses/services/statuse.service';
    import TaskService from '@/services/resource/task.service.js';
    import ProjectService from '@/services/resource/project.service.js';
    import TaskPolicy from '@/policies/task.policy.js';
    import DatePicker from '@/components/DatePicker.vue';
    import SprintsDropdown from './SprintsDropdown.vue';
    import StoryPointsInput from './StoryPointsInput.vue';
    import { mapGetters } from 'vuex';

    export default {
        components: { UserAvatar, DatePicker, SprintsDropdown, StoryPointsInput },
        props: {
            task: { type: Object, required: true },
        },
        data() {
            const baseUrl = process.env.VUE_APP_API_URL;

            return {
                statusDropdownOpen: false,
                detailsDropdownOpen: true,
                sprintDropdownOpen: false,
                editingStoryPoints: false,
                usersDropdownOpen: false,
                tagsDropdownOpen: false,
                isEditing: false,

                isInitialized: false,

                selectedDate: this.task.deadline,
                baseUrl,

                statuses: [],
                sprints: [],

                users: [],
                assignedUsers: [],

                tags: [],
                assignedTags: [],
                assignedTagsIds: [],

                isDragging: false,
                searchQuery: '',

                statusService: new StatusService(),
                taskService: new TaskService(),
                projectService: new ProjectService(),
            };
        },
        async mounted() {
            this.assignedUsers = this.task.users.map(u => u.attributes);
            this.assignedTags = [...this.task.tags].sort((a, b) => a.attributes.name.localeCompare(b.attributes.name));

            this.assignedTagsIds = this.assignedTags.map(tag => tag.id);

            await this.fetchStatuses();
            await this.fetchProject();
            await this.fetchTags();
        },

        computed: {
            ...mapGetters('user', ['user']),
            assignedUsersIds: {
                get() {
                    return this.assignedUsers.map(user => user.id);
                },
                set(newIds) {
                    this.assignedUsers = this.users.filter(user => newIds.includes(user.id));
                },
            },
            filteredUsers() {
                if (!this.searchQuery) return this.users;
                return this.users.filter(user => user.full_name.toLowerCase().includes(this.searchQuery.toLowerCase()));
            },
            filteredTags() {
                if (!this.searchQuery) return this.tags;
                return this.tags.filter(tag =>
                    tag.attributes.name.toLowerCase().includes(this.searchQuery.toLowerCase()),
                );
            },
            hasUpdate() {
                return TaskPolicy.update(this.user);
            },
        },
        methods: {
            handleUserChange() {
                this.updateUsers(this.assignedUsers);
            },
            toggleStatusDropdown() {
                this.statusDropdownOpen = !this.statusDropdownOpen;
            },
            closeUsersDropdown() {
                this.usersDropdownOpen = false;
            },
            toggleDetailsDropdown() {
                this.detailsDropdownOpen = !this.detailsDropdownOpen;
            },
            toggleSprintDropdown() {
                this.sprintDropdownOpen = !this.sprintDropdownOpen;
            },
            selectSprint(sprint) {
                this.task.sprint = sprint;
                this.sprintDropdownOpen = false;
            },
            toggleUsersDropdown() {
                this.usersDropdownOpen = !this.usersDropdownOpen;
            },
            toggleTagsDropdown() {
                this.tagsDropdownOpen = !this.tagsDropdownOpen;
            },
            openEditor() {
                this.selectedDate = this.task.deadline || null;
                this.isEditing = true;

                this.$nextTick(() => {
                    if (this.$refs.datePicker) {
                        const input = this.$refs.datePicker.$el.querySelector('input');
                        if (input) {
                            input.focus();
                            input.click();
                        }
                    }
                });
            },

            closeDeadlineEditor() {
                this.isEditing = false;
            },
            closeTagsDropdown() {
                this.tagsDropdownOpen = false;
            },
            formatSpentTime(seconds) {
                if (seconds === 0) return '-';

                const hrs = Math.floor(seconds / 3600);
                const mins = Math.floor((seconds % 3600) / 60);
                const secs = seconds % 60;

                return `${hrs}h ${mins.toString().padStart(2, '0')}m ${secs.toString().padStart(2, '0')}s`;
            },

            async updateStatus(status) {
                const formData = new FormData();
                formData.append('task[status_id]', status.attributes.id);

                try {
                    await this.taskService.update(formData, this.task.id);

                    this.task.status = status.attributes;
                    this.successSaveMessage();
                } catch (error) {
                    console.log('Error update task status', error);
                }

                this.statusDropdownOpen = false;
            },

            async updateDeadline() {
                if (this.selectedDate !== this.task.deadline) {
                    const formData = new FormData();
                    formData.append('task[deadline]', this.selectedDate);

                    try {
                        await this.taskService.update(formData, this.task.id);

                        this.successSaveMessage();
                    } catch (error) {
                        console.log('Error update Deadline task status', error);
                    }
                }
                this.isEditing = false;
            },

            async updateUsers(users) {
                const formData = new FormData();
                const userIDs = users.map(user => user.id);
                userIDs.forEach(id => formData.append('users[]', id));
                formData.append('task[task_name]', this.task.task_name);

                try {
                    await this.taskService.update(formData, this.task.id);
                } catch (error) {
                    console.log('Error update task users', error);
                }
            },

            async updateTaskTags(tagIds) {
                const service = this.$store.getters['tag/service'];
                try {
                    await service.addTags({
                        object_id: this.task.id,
                        object_model_name: 'task',
                        tag_ids: tagIds,
                    });
                } catch (error) {
                    console.log('Error update task users', error);
                }
            },
            formatDate(dateStr) {
                const date = new Date(dateStr);

                const formattedDate = date.toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                });

                return formattedDate;
            },

            getAvatarUrl(user) {
                return `${this.baseUrl}${user.attributes.avatar_url}`;
            },

            async fetchStatuses() {
                try {
                    const { data } = await this.statusService.getAll({ disable_pagy: true });

                    this.statuses = data.data;
                } catch (error) {
                    console.log('Error status index request', error);
                }
            },

            async fetchTags() {
                const tags = await this.$store.dispatch('tag/getTaskTags');
                this.tags = tags;
            },

            async fetchProject() {
                const projectId = this.task.project_id;
                try {
                    const { data } = await this.projectService.getItem(projectId);

                    this.users = data.data.attributes.users.map(user => {
                        return {
                            ...user,
                            full_name: `${user.first_name} ${user.last_name}`,
                        };
                    });
                } catch (error) {
                    console.log('Error user index request', error);
                }
            },

            successDeleteMessage() {
                this.$Notify({
                    type: 'success',
                    title: this.$t('notification.record.delete.success.title'),
                    message: this.$t('notification.record.delete.success.message'),
                });
            },

            successSaveMessage() {
                this.$Notify({
                    type: 'success',
                    title: this.$t('notification.save.success.title'),
                    message: this.$t('notification.save.success.message'),
                });
            },
        },

        watch: {
            assignedTagsIds(value) {
                if (!this.isInitialized) {
                    this.isInitialized = true;
                    return;
                }
                this.updateTaskTags(value);
            },

            'task.tags': {
                handler(newTags) {
                    this.assignedTags = [...newTags].sort((a, b) => a.attributes.name.localeCompare(b.attributes.name));

                    this.isInitialized = false;
                    this.assignedTagsIds = newTags.map(tag => tag.id);
                },
                deep: true,
            },
        },
    };
</script>

<style scoped lang="scss">
    .dropdown-separator {
        border: none;
        border-top: 1px solid #e0e0e0;
        margin: 8px 0;

        .theme-dark & {
            border-color: #555;
        }
    }
    .task-details {
        background: #fff;
        padding: 1rem;
        border-radius: 0.5rem;
        border: 1px solid #e5e7eb;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    .details-content {
        padding: 1rem;
        background: #f9fafb;
        border: 1px solid #e5e7eb;
        border-radius: 0.5rem;

        .theme-dark & {
            background-color: #333;
            border-color: #333;
        }
    }

    .details-content li {
        padding: 0.5rem 1rem;
        border-radius: 8px;
        cursor: pointer;
        transition: background 0.2s ease-in-out;
    }

    .status-item {
        &:hover {
            background-color: #e5e7eb;
        }

        .theme-dark & {
            &:hover {
                background-color: #555;
            }
        }
    }

    .info-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0;
    }

    .assignees {
        display: flex;
        align-items: center;
        gap: 10px;
        flex-wrap: wrap;
    }

    .assigned-user {
        display: flex;
        align-items: center;
    }

    .search-input {
        padding: 8px;
        border: 1px solid #ccc;
        border-radius: 4px;
        width: 100%;
        transition: border-color 0.3s, box-shadow 0.3s;

        .theme-dark & {
            background-color: #333;
            border-color: #555;
            color: #ffa500;
        }

        &:focus {
            border-color: #176bda;
            outline: none;
            box-shadow: 0 0 10px rgba(0, 50, 212, 0.2);
            .theme-dark & {
                border-color: #ffa500;
                box-shadow: 0 0 10px rgba(255, 165, 0, 0.4);
            }
        }
    }

    .add-user {
        width: 32px;
        height: 32px;
        border: none;
        background: #e5e7eb;
        border-radius: 50%;
        font-size: 18px;
        cursor: pointer;

        &:hover {
            background: #d1d5db;
        }

        .theme-dark & {
            background-color: #555;
            &:hover {
                background-color: #444;
                border-color: #555;
                color: #c4c4cf;
            }
        }
    }

    /* Tags */
    .tags {
        display: flex;
        gap: 5px;
    }

    .tag {
        width: 24px;
        height: 24px;
        border-radius: 4px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        cursor: pointer;
    }

    .add-tag {
        width: 32px;
        height: 32px;
        border: none;
        background: #e5e7eb;
        border-radius: 50%;
        font-size: 18px;
        cursor: pointer;

        &:hover {
            background: #d1d5db;
        }

        .theme-dark & {
            background-color: #555;
            &:hover {
                background-color: #444;
                border-color: #555;
                color: #c4c4cf;
            }
        }
    }

    .add-tag:hover {
        background: #d1d5db;
    }

    /* Sprint */
    .dropdown-wrapper {
        position: relative;
    }

    .sprint-button {
        background: none;
        border: none;
        color: #2563eb;
        cursor: pointer;
    }

    .sprint-button:hover {
        text-decoration: underline;
    }

    .dropdown-wrapper {
        position: relative;
        display: inline-block;
    }

    .sprint-button {
        background: none;
        border: none;
        color: #2563eb;
        cursor: pointer;
        font-weight: 600;
        padding: 5px 10px;
        border-radius: 5px;
        transition: background 0.2s ease-in-out;
    }

    .sprint-button:hover {
        background: rgba(37, 99, 235, 0.1);
    }

    .dropdown-float {
        position: absolute;
        top: 100%;
        right: 0;
        min-width: 120px;
        max-width: 250px;
        background: white;
        border: 1px solid #e5e7eb;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        overflow: hidden;
        white-space: nowrap;
        z-index: 10;
        transition: all 0.2s ease-in-out;
    }

    .dropdown-float li {
        padding: 10px 14px;
        cursor: pointer;
        transition: background 0.2s ease-in-out;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .dropdown-float li:hover {
        background: #f3f4f6;
    }

    .dropdown-menu {
        position: absolute;
        top: 100%;
        right: 0;
        min-width: 120px;
        max-width: 250px;
        background: white;
        border: 1px solid #e5e7eb;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        overflow: hidden;
        white-space: nowrap;
        z-index: 10;
        opacity: 0;
        transform: scaleY(0);
        transform-origin: top;
        transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;
    }

    .dropdown-menu.open {
        opacity: 1;
        transform: scaleY(1);
        padding: 0.5rem;
        background: #fff;
        border: 1px solid #e0e0e0;
        border-radius: 6px;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        list-style: none;
        font-size: 14px;
    }

    .dropdown-menu li {
        padding: 10px 16px;
        cursor: pointer;
        transition: background 0.2s ease-in-out, color 0.2s ease-in-out;
        border-radius: 4px;
    }

    .dropdown-menu li:hover {
        background: #f3f4f6;
        color: #2563eb;
    }

    .dropdown-menu li.active {
        background: #2563eb;
        color: white;
        font-weight: bold;
    }

    .dropdown-menu[style*='display: block'] {
        opacity: 1;
        transform: scaleY(1);
    }

    .dropdown-menu {
        opacity: 0;
        transform: scaleY(0);
        transform-origin: top;
        transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
    }

    .dropdown-menu[style*='display: block'] {
        opacity: 1;
        transform: scaleY(1);
    }

    .dropdown-menu.details-content {
        display: block;
        opacity: 1;
        transform: scaleY(1);
    }

    .story-points-text {
        cursor: pointer;
        margin: 0 20px 0 0;
    }

    .story-points-input {
        width: 50px;
        padding: 0.2rem;
        border: 1px solid #e5e7eb;
        border-radius: 4px;
    }

    .sprint-dropdown {
        width: 150px;
        right: 0;
        left: auto;
    }

    .sprint-dropdown li {
        text-align: left;
    }

    .footer {
        display: flex;
        justify-content: space-between;
    }

    .tags-dropdown {
        position: absolute;
        top: 50%;
        right: 6%;
        min-width: 150px;
        background: white;
        border: 1px solid #e5e7eb;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        overflow: hidden;
        white-space: nowrap;
        z-index: 10;
        transition: all 0.2s ease-in-out;
        padding: 1rem;
    }

    .tags-dropdown li {
        display: flex;
        align-items: center;
        padding: 10px 14px;
        cursor: pointer;
        transition: background 0.2s ease-in-out;
    }

    .tags-dropdown li:hover {
        background: #f3f4f6;
    }

    .tag-color {
        display: inline-block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin-right: 8px;
    }

    .users-dropdown {
        position: absolute;
        top: 40%;
        right: 6%;
        min-width: 300px;
        background: white;
        border: 1px solid #e5e7eb;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        overflow: hidden;
        z-index: 10;
        transition: all 0.2s ease-in-out;
        padding: 0.8rem;
        margin-top: 5px;

        .theme-dark & {
            color: #ffa500;
            background-color: #333;
            border-color: #444;
        }
    }

    .users-dropdown {
        padding: 10px;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    }

    .tags-dropdown {
        position: absolute;
        top: 47%;
        right: 6%;
        min-width: 300px;
        background: white;
        border: 1px solid #e5e7eb;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        border-radius: 6px;
        overflow: hidden;
        z-index: 10;
        transition: all 0.2s ease-in-out;
        padding: 0.8rem;
        margin-top: 5px;

        .theme-dark & {
            color: #ffa500;
            background-color: #333;
            border-color: #444;
        }
    }

    .dropdown-item {
        padding: 8px;
        border-bottom: 1px solid #f0f0f0;

        .theme-dark & {
            border-color: #444;
        }
    }
    .user-item {
        display: flex;
        padding: 0.5rem;
        align-items: center;
        border-radius: 8px;
        gap: 10px;

        &:hover {
            background-color: #e5e7eb;
        }

        .theme-dark & {
            color: #ffa500;

            &:hover {
                background-color: #444;
                color: #c4c4cf;
            }
        }
    }

    .tag-item {
        display: flex;
        padding: 0.5rem;
        align-items: center;
        border-radius: 8px;
        gap: 10px;

        &:hover {
            background-color: #e5e7eb;
        }

        .theme-dark & {
            color: #ffa500;

            &:hover {
                background-color: #444;
                color: #c4c4cf;
            }
        }
    }

    .custom-checkbox {
        width: 18px;
        height: 18px;
        accent-color: #007bff;
        cursor: pointer;
    }

    .user-name {
        font-size: 14px;
        font-weight: 500;
        color: #333;

        .theme-dark & {
            color: #ffa500;
        }
    }

    .assigned-users-container {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        max-width: 200px;
        justify-content: space-between;
    }

    .selected-tags {
        display: flex;
        flex-wrap: wrap;
        gap: 8px;
        max-width: 200px;
        justify-content: space-between;
    }

    .deadline-data {
        cursor: pointer;
    }

    ::v-deep .picker {
        input {
            max-width: 90px;
        }
    }
</style>
