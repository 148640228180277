<template>
    <div v-click-outside="closeDropDown" class="sprint-dropdown">
        <button class="dropdown-btn" @click="toggleDropdown">
            {{ selectedSprint ? selectedSprint.name : 'Select Sprint' }}
        </button>

        <transition name="fade">
            <ul v-if="dropdownOpen" class="dropdown-list">
                <li
                    v-for="sprint in sprints"
                    :key="sprint.attributes.id"
                    :class="{ selected: sprint.attributes.id === selectedSprint?.id }"
                    @click="selectSprint(sprint)"
                >
                    {{ sprint.attributes.name }}
                </li>
            </ul>
        </transition>
    </div>
</template>

<script>
    import SprintService from '../../sprints/services/sprint.service';
    import TaskService from '../../../services/resource/task.service';
    export default {
        props: {
            task: { type: Object, required: true },
        },
        data() {
            return {
                dropdownOpen: false,
                selectedSprint: null,
                sprints: [],

                service: new SprintService(),
                taskService: new TaskService(),
            };
        },
        async created() {
            await this.fetchSprints();

            this.assignedSprint();
        },
        methods: {
            toggleDropdown() {
                this.dropdownOpen = !this.dropdownOpen;
            },
            closeDropDown() {
                this.dropdownOpen = false;
            },
            selectSprint(sprint) {
                this.selectedSprint = sprint.attributes;
                this.dropdownOpen = false;

                this.updateTaskSprint();
            },

            assignedSprint() {
                this.selectedSprint = this.task.sprint;
            },

            async updateTaskSprint() {
                const formData = new FormData();
                formData.append('task[sprint_id]', this.selectedSprint.id);
                try {
                    await this.taskService.update(formData, this.task.id);
                } catch (error) {
                    console.log('Error update Task rquest', error);
                }
            },

            async fetchSprints() {
                try {
                    const { data } = await this.service.getAll();
                    this.sprints = data.data;
                } catch (error) {
                    console.log('Error Sprint index request', error);
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    .sprint-dropdown {
        position: relative;
        display: flex;
        justify-content: flex-end;
        width: 200px;

        .dropdown-btn {
            color: #1f1f1f;
            border: none;
            border-radius: 8px;
            cursor: pointer;
            transition: background 0.3s;

            .theme-dark & {
                color: #ffa500;
                &:hover {
                    border-color: #555;
                    color: #c4c4cf;
                }
            }
        }

        .dropdown-list {
            position: absolute;
            top: 100%;
            width: 100%;
            margin-top: 5px;
            background: white;
            border-radius: 8px;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
            overflow: hidden;
            z-index: 10;
            border: 1px solid #d1d5db;

            li {
                padding: 10px 15px;
                cursor: pointer;
                transition: background 0.3s;

                font-size: 14px;

                &:hover {
                    background: #d1d5db;
                }

                &.selected {
                    background: #d1d5db;
                    color: #1f1f1f;
                    font-weight: bold;
                }
            }

            .theme-dark & {
                background: #333;
                border-color: #555;

                li {
                    &:hover {
                        background-color: #555;
                        color: #c4c4cf;
                    }

                    &.selected {
                        background-color: #555;
                        color: #c4c4cf;
                    }
                }
            }
        }
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 0.2s;
    }
    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }
</style>
