<template>
    <div class="page-container">
        <div class="page-header">
            <h1>{{ $t('projects.grid-title') }}</h1>
        </div>
        <div class="projects-container">
            <div class="projects-header">
                <at-input
                    v-model="searchQuery"
                    type="text"
                    :placeholder="$t('projects.search_placeholder')"
                    class="search-input"
                />
                <div class="header-buttons">
                    <button
                        class="filter-button at-btn crud__control-items__item at-btn--primary at-btn--large"
                        @click="toggleFilters"
                    >
                        {{ $t('projects.filters') }}
                    </button>
                    <button
                        v-if="hasCreate"
                        class="create-button at-btn crud__control-items__item at-btn--primary at-btn--large"
                        @click="$router.push({ path: '/projects/new' })"
                    >
                        <i class="at-btn__icon icon icon-edit"></i>
                        {{ $t('projects.create_project') }}
                    </button>
                </div>
                <project-filter
                    :visible="filterModalVisible"
                    @apply-filters="handleFiltersChange"
                    @close="closeFilters"
                />
            </div>

            <ul v-if="projects.length" class="projects-list">
                <li class="project-row header-row">
                    <div class="project-cell project-name">{{ $t('projects.project_table.name') }}</div>
                    <div class="project-cell project-ref">{{ $t('projects.project_table.shortcut') }}</div>
                    <div class="project-cell project-users">{{ $t('projects.project_table.users') }}</div>
                    <div class="project-cell project-actions">{{ $t('projects.project_table.actions') }}</div>
                </li>
                <li v-for="project in projects" :key="project.attributes.id" class="project-row">
                    <div class="project-cell project-name">{{ project.attributes.name }}</div>
                    <div class="project-cell project-ref">{{ project.attributes.shortcut }}</div>
                    <ProjectUsers :users="project.attributes.users" />
                    <div class="project-cell project-actions">
                        <button
                            class="project-action show-button at-btn at-btn--primary at-btn--large action-button"
                            @click="$router.push({ path: `/projects/view/${project.attributes.id}` })"
                        >
                            <font-awesome-icon :icon="['fas', 'eye']" />
                            {{ $t('projects.show') }}
                        </button>
                        <button
                            class="project-action show-button at-btn at-btn--primary at-btn--large action-button"
                            @click="$router.push({ name: 'project.members', params: { id: project.attributes.id } })"
                        >
                            <font-awesome-icon :icon="['fas', 'user']" />
                            Members
                        </button>
                        <button
                            v-if="hasDelete"
                            class="project-action delete-button at-btn at-btn--error at-btn--large action-button"
                            @click="deleteProject(project.attributes.id)"
                        >
                            <font-awesome-icon :icon="['fas', 'trash']" />
                            {{ $t('projects.delete') }}
                        </button>
                    </div>
                </li>
            </ul>

            <div v-else class="no-projects-message">
                {{ $t('projects.no_projects') }}
            </div>
        </div>
        <div v-if="projects && projects.length" class="pagination">
            <at-pagination :total="totalItems" :current="page" :page-size="itemsPerPage" @page-change="onPageChange">
                <template #previous-text>{{ $t('pagination.previous') }}</template>
                <template #next-text>{{ $t('pagination.next') }}</template>
            </at-pagination>
        </div>
        <delete-modal
            :visible="deleteModalVisible"
            :title="$t('delete_modal.title')"
            :message="$t('delete_modal.message')"
            @confirm="confirmDelete"
            @close="closeDeleteModal"
        />
    </div>
</template>

<script>
    import { debounce } from 'lodash';
    import ProjectsService from '../../../../services/resource/project.service';
    import ProjectFilter from '../../components/ProjectFilter.vue';
    import ProjectUsers from '../../components/ProjectUsers.vue';
    import { mapGetters } from 'vuex';
    import ProjectPolicy from '@/policies/project.policy.js';
    import DeleteModal from '../../../../components/DeleteModal.vue';

    export default {
        components: { ProjectUsers, ProjectFilter, DeleteModal },
        data() {
            return {
                projects: [],
                searchQuery: '',
                filterModalVisible: false,

                service: new ProjectsService(),

                filters: {
                    users: [],
                },

                page: +(this.$route.query.page || 1),
                totalItems: 0,
                itemsPerPage: 15,
                queryParams: {
                    page: this.$route.query.page,
                },

                deleteModalVisible: false,
                deleteProjectId: null,
            };
        },
        computed: {
            ...mapGetters('user', ['user']),
            hasCreate() {
                return ProjectPolicy.create(this.user);
            },
            hasDelete() {
                return ProjectPolicy.delete(this.user);
            },
        },
        async mounted() {
            await this.fetchProjects();
        },
        methods: {
            toggleFilters() {
                this.filterModalVisible = !this.filterModalVisible;
            },
            closeFilters() {
                this.filterModalVisible = false;
            },
            handleFiltersChange: debounce(function (filters) {
                this.filters = filters;
                this.fetchProjects();
            }, 300),

            async onPageChange(page) {
                if (this.queryParams.page === page) return;
                this.queryParams.page = page;
                this.page = page;
                await this.fetchProjects();
            },

            closeDeleteModal() {
                this.deleteModalVisible = false;
            },
            deleteProject(projectId) {
                this.deleteProjectId = projectId;
                this.deleteModalVisible = true;
            },

            confirmDelete() {
                this.service.deleteItem(this.deleteProjectId).then(() => {
                    this.deleteModalVisible = false;
                    this.deleteProjectId = null;
                    this.successDeleteMessage();
                    this.fetchProjects();
                });
            },

            successDeleteMessage() {
                this.$Notify({
                    type: 'success',
                    title: this.$t('notification.record.delete.success.title'),
                    message: this.$t('notification.record.delete.success.message'),
                });
            },

            async fetchProjects() {
                const queryParams = {
                    page: this.page,
                    search: { query: this.searchQuery },
                    user_ids: this.filters.users,
                };
                try {
                    const res = await this.service.getWithFilters(queryParams);

                    const { data, pagination } = res.data;
                    this.totalItems = pagination.total;
                    this.page = pagination.page;
                    this.itemsPerPage = 1;
                    this.projects = data;
                } catch ({ response }) {
                    if (process.env.NODE_ENV === 'development') {
                        console.warn(response ? response : 'request is canceled');
                    }
                }
            },
        },
        watch: {
            searchQuery: debounce(function () {
                this.fetchProjects();
            }, 350),
        },
    };
</script>

<style scoped lang="scss">
    @import '../../styles/actions/List.scss';
</style>
