<template>
    <div class="project-details">
        <!-- Logo Dropdown -->
        <div class="dropdown-container">
            <button class="dropdown-button" @click="toggleDropdown('logo')">
                Logo
                <font-awesome-icon :icon="['fas', isLogoOpen ? 'chevron-up' : 'chevron-down']" />
            </button>
            <div v-if="isLogoOpen" class="dropdown-menu">
                <div class="logo-container">
                    <img v-if="previewUrl" :src="previewUrl" alt="Project Logo" class="logo-preview" />
                    <div v-else class="logo-placeholder">
                        <button class="add-logo-button" @click="selectFile">
                            <font-awesome-icon :icon="['fas', 'plus']" />
                        </button>
                    </div>
                </div>
                <input ref="fileInput" type="file" hidden @change="handleFileChange" />

                <div v-if="selectedFile || previewUrl" class="logo-actions">
                    <at-tooltip :content="'Choose File'">
                        <button class="icon-button" @click="selectFile">
                            <font-awesome-icon :icon="['fas', 'image']" />
                        </button>
                    </at-tooltip>
                    <at-tooltip v-if="selectedFile" :content="'Upload'">
                        <button class="icon-button" @click="uploadLogo">
                            <font-awesome-icon :icon="['fas', 'upload']" />
                        </button>
                    </at-tooltip>
                    <at-tooltip :content="'Remove'">
                        <button class="icon-button remove-button" @click="removeLogo">
                            <font-awesome-icon :icon="['fas', 'trash']" />
                        </button>
                    </at-tooltip>
                </div>
            </div>
        </div>

        <!-- Users Dropdown -->
        <div class="dropdown-container">
            <button class="dropdown-button" @click="toggleUserDropdown('users')">
                Users
                <font-awesome-icon :icon="['fas', isUsersOpen ? 'chevron-up' : 'chevron-down']" />
            </button>
            <div v-if="isUsersOpen" class="dropdown-menu vertical-layout">
                <div v-for="(role, key) in roles" :key="key" class="role-section">
                    <button class="role-toggle" @click="toggleRole(key)">
                        {{ role.title }}
                        <font-awesome-icon :icon="['fas', expandedRoles[key] ? 'chevron-up' : 'chevron-down']" />
                    </button>
                    <ul v-if="expandedRoles[key]">
                        <li v-for="user in usersByRole[key]" :key="user.id" class="user-item">
                            <img :src="user.avatar" alt="avatar" class="user-avatar" />
                            <span>{{ user.name }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <!-- collaborators -->
        <div class="dropdown-container">
            <button class="dropdown-button" @click="toggleCollaboratorsDropdown('users')">
                Collaborators
                <font-awesome-icon :icon="['fas', isCollaboratorsOpen ? 'chevron-up' : 'chevron-down']" />
            </button>
            <div v-if="isCollaboratorsOpen" class="dropdown-menu vertical-layout">
                <h1>WIP</h1>
                <p>Collaborators section</p>
            </div>
        </div>

        <div class="dropdown-separator"></div>

        <!-- Footer -->
        <div class="footer">
            <div class="footer-left">
                <div class="spent-time">
                    <strong>{{ $t('task.spent-time') }}:</strong>
                    {{ formatSpentTime(project.project_time) }}
                </div>
            </div>
            <div class="footer-right">
                <div>
                    <strong>{{ $t('task.created') }}:</strong> {{ formatDate(project.created_at) }}
                </div>
                <div>
                    <strong>{{ $t('task.updated') }}:</strong> {{ formatDate(project.updated_at) }}
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import LogoService from '../services/logo.service';
    export default {
        props: {
            project: { type: Object, required: true },
        },
        data() {
            const baseUrl = process.env.VUE_APP_API_URL;

            return {
                isLogoOpen: true,
                isUsersOpen: false,
                isCollaboratorsOpen: false,

                users: [
                    { id: 1, name: 'Alice', role: 'owner', avatar: 'https://i.pravatar.cc/40?u=alice' },
                    { id: 2, name: 'Bob', role: 'maintainer', avatar: 'https://i.pravatar.cc/40?u=bob' },
                    { id: 3, name: 'Charlie', role: 'member', avatar: 'https://i.pravatar.cc/40?u=charlie' },
                    { id: 4, name: 'David', role: 'member', avatar: 'https://i.pravatar.cc/40?u=david' },
                ],
                expandedRoles: {},
                roles: {
                    owner: { title: 'Owner' },
                    maintainer: { title: 'Maintainer' },
                    member: { title: 'Member' },
                },
                baseUrl,
                previewUrl: null,
                selectedFile: null,

                logoService: new LogoService(),
            };
        },
        computed: {
            usersByRole() {
                return {
                    owner: this.users.filter(user => user.role === 'owner'),
                    maintainer: this.users.filter(user => user.role === 'maintainer'),
                    member: this.users.filter(user => user.role === 'member'),
                };
            },
        },
        async created() {
            this.assignedLogo();
        },
        methods: {
            toggleUserDropdown() {
                this.isUsersOpen = !this.isUsersOpen;
            },
            toggleCollaboratorsDropdown() {
                this.isCollaboratorsOpen = !this.isCollaboratorsOpen;
            },
            toggleRole(role) {
                this.$set(this.expandedRoles, role, !this.expandedRoles[role]);
            },
            toggleDropdown(type) {
                if (type === 'logo') this.isLogoOpen = !this.isLogoOpen;
                if (type === 'users') this.isUsersOpen = !this.isUsersOpen;
            },

            selectFile() {
                this.$refs.fileInput.click();
            },
            handleFileChange(event) {
                const file = event.target.files[0];
                if (file) {
                    this.selectedFile = file;
                    this.previewUrl = URL.createObjectURL(file);
                }
            },
            uploadLogo() {
                if (!this.selectedFile) return;
                this.uploadProjectLogo();

                this.selectedFile = null;
            },
            removeLogo() {
                this.previewUrl = null;
                this.selectedFile = null;
                this.$refs.fileInput.value = '';
                this.removeProjectLogo();
            },

            formatDate(dateStr) {
                return new Date(dateStr).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: 'short',
                    year: 'numeric',
                });
            },
            formatSpentTime(seconds) {
                if (!seconds) return '-';
                const hrs = Math.floor(seconds / 3600);
                const mins = Math.floor((seconds % 3600) / 60);
                return `${hrs}h ${mins.toString().padStart(2, '0')}m`;
            },

            assignedLogo() {
                if (!this.project.logo.id) return;

                const logoUrl = `${this.baseUrl}/${this.project.logo.image_url}`;
                this.previewUrl = logoUrl;
            },

            async uploadProjectLogo() {
                const formData = new FormData();
                formData.append('logo[image]', this.selectedFile);
                formData.append('logo[project_id]', this.project.id);

                try {
                    await this.logoService.create(formData);

                    this.$Notify({
                        type: 'success',
                        title: this.$t('notification.save.success.title'),
                        message: 'Logo added successfully!',
                    });
                } catch (error) {
                    console.log('Error upload logo request', error);
                }
            },

            async removeProjectLogo() {
                const logoId = this.project.logo.id;
                try {
                    await this.logoService.destroy(logoId);

                    this.successDeleteMessage();
                } catch (error) {
                    console.log('Error remove logo request', error);
                }
            },

            successDeleteMessage() {
                this.$Notify({
                    type: 'success',
                    title: this.$t('notification.record.delete.success.title'),
                    message: this.$t('notification.record.delete.success.message'),
                });
            },
        },
    };
</script>

<style scoped lang="scss">
    .dropdown-separator {
        border: none;
        border-top: 1px solid #e0e0e0;
        margin: 8px 0;

        .theme-dark & {
            border-color: #555;
        }
    }
    .project-details {
        background: #fff;
        padding: 1rem;
        border-radius: 0.5rem;
        border: 1px solid #e5e7eb;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

        .theme-dark & {
            background: #222;
            border-color: #444;
        }
    }

    .dropdown-container {
        margin-bottom: 1rem;
    }

    .dropdown-button {
        padding: 0.5rem 1rem 0;
        border-radius: 8px;
        cursor: pointer;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: space-between;
    }

    .dropdown-menu {
        background: white;
        border: 1px solid #e5e7eb;
        border-radius: 8px;
        padding: 1rem;
        margin-top: 0.5rem;
        text-align: center;

        .theme-dark & {
            background-color: #333;
            border-color: #333;
        }
    }

    .logo-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
    }

    .logo-preview {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        border: 1px solid #ddd;
        object-fit: cover;
    }

    .logo-placeholder {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background-color: #ddd;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-weight: bold;
        color: #555;
    }

    .button-container {
        display: flex;
        justify-content: center;
        gap: 10px;
    }

    .logo-actions {
        display: flex;
        gap: 30px;
        justify-content: center;
        margin-top: 10px;
    }

    .icon-button {
        border: none;
        background: transparent;
        cursor: pointer;
        font-size: 1.2rem;
        color: #6b7280;

        &:hover {
            color: #c4c4cf;
        }

        .theme-dark & {
            &:hover {
                color: #ffa500;
            }
        }
    }

    .remove-button {
        &:hover {
            color: #c0392b;
        }
    }

    .footer {
        padding: 0.5rem 1rem 0;
        margin-top: 1rem;
        display: flex;
        justify-content: space-between;
    }

    .vertical-layout {
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding: 10px;
        background: #fff;
        border-radius: 8px;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    }

    .role-section {
        background: #f3f4f6;
        padding: 10px;
        border-radius: 8px;

        .theme-dark & {
            background-color: #444;
        }
    }

    .role-title {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 5px;
        text-align: center;
    }

    .user-item {
        display: flex;
        align-items: center;
        gap: 10px;
        padding: 5px;
        border-radius: 5px;
    }

    .user-item:hover {
        background: #e5e7eb;

        .theme-dark & {
            background: #555;
        }
    }

    .user-avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
    }
</style>
