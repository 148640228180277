<template>
    <div class="kanban-board">
        <div v-for="role in roles" :key="role.attributes.id" class="kanban-column" @dragover.prevent @drop="drop(role)">
            <div class="header-section">
                <h2>{{ capitalize(role.attributes.name) }}</h2>
                <div class="add-user-column">
                    <button
                        v-show="role.attributes.name !== 'owner'"
                        class="create-button at-btn crud__control-items__item at-btn--primary at-btn--large"
                        @click="toggleDropdown(role)"
                    >
                        Add Users
                    </button>
                    <div v-if="dropdownRole === role" :ref="`dropdown-${role.attributes.id}`" class="dropdown">
                        <ul v-if="availableUsers.length > 0">
                            <li v-for="user in availableUsers" :key="user.id" @click="addUserToRole(user, role)">
                                <div class="user-info">
                                    <div class="avatar-container">
                                        <img
                                            v-if="user.attributes.avatar_url"
                                            :src="userAvatarUrl(user)"
                                            alt="User Avatar"
                                            class="avatar"
                                        />
                                        <div v-else class="avatar-placeholder">
                                            {{
                                                `${user.attributes.first_name.charAt(
                                                    0,
                                                )}${user.attributes.last_name.charAt(0)}`
                                            }}
                                        </div>
                                    </div>
                                    <span class="user-name">
                                        {{ `${user.attributes.first_name} ${user.attributes.last_name}` }}
                                    </span>
                                </div>
                            </li>
                        </ul>
                        <p v-else class="no-users-message">No available users</p>
                    </div>
                </div>
            </div>

            <div class="dropdown-separator"></div>

            <div class="kanban-items-container">
                <div
                    v-for="user in roleUsers(role)"
                    :key="user.id"
                    class="kanban-item"
                    :class="{ clicked: selectedUser && selectedUser.attributes.id === user.attributes.id }"
                    draggable="true"
                    @dragstart="drag(user, role)"
                >
                    <div class="user-info" @click="toggleUserSelection(user)">
                        <div class="left-section">
                            <div class="avatar-container">
                                <img
                                    v-if="user.attributes.avatar_url"
                                    :src="userAvatarUrl(user)"
                                    alt="User Avatar"
                                    class="avatar"
                                />
                                <div v-else class="avatar-placeholder">
                                    {{
                                        `${user.attributes.first_name.charAt(0)}${user.attributes.last_name.charAt(0)}`
                                    }}
                                </div>
                            </div>
                            <span class="user-name">
                                {{ `${user.attributes.first_name} ${user.attributes.last_name}` }}
                            </span>
                        </div>

                        <span
                            v-show="!ownerRole(role)"
                            v-if="selectedUser && selectedUser.attributes.id === user.attributes.id"
                            class="remove-user"
                            @click.stop="removeUser(user)"
                        >
                            <font-awesome-icon :icon="['fas', 'xmark']" />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import UserService from '@/services/resource/user.service';
    import ProjectService from '@/services/resource/project.service';
    import RoleService from '@/services/resource/role.service';

    let reconnectInterval = 5000;
    let maxRetries = 10;
    let retries = 0;

    export default {
        data() {
            return {
                roles: null,
                allUsers: [],
                project: null,
                dropdownRole: null,
                selectedUser: null,

                projectService: new ProjectService(),
                userService: new UserService(),
                roleService: new RoleService(),

                wsUrl: process.env.VUE_APP_WS_URL || '',
                ws: null,
            };
        },
        computed: {
            availableUsers() {
                const assignedUserIds = this.project.users.map(u => u.id);
                return this.allUsers.filter(user => !assignedUserIds.includes(user.id));
            },
        },
        mounted() {
            document.addEventListener('click', this.handleClickItemOutside);
        },
        beforeDestroy() {
            document.removeEventListener('click', this.handleClickOutside);
            document.removeEventListener('click', this.handleClickItemOutside);
        },
        async created() {
            await this.fetchProject();
            await this.fetchUsers();
            await this.fetchRoles();

            this.connectWebSocket();
        },
        methods: {
            userAvatarUrl(user) {
                return `${process.env.VUE_APP_API_URL}/${user.attributes.avatar_url}`;
            },
            ownerRole(role) {
                return role.attributes.name === 'owner';
            },
            capitalize(text) {
                if (!text) return '';
                return text.charAt(0).toUpperCase() + text.slice(1);
            },
            handleClickOutside(event) {
                const dropdown = this.$refs[`dropdown-${this.dropdownRole?.attributes.id}`]?.[0];
                const isInsideDropdown = dropdown && dropdown.contains(event.target);

                if (!isInsideDropdown) {
                    this.dropdownRole = null;
                    document.removeEventListener('click', this.handleClickOutside);
                }
            },

            handleClickItemOutside(event) {
                const isInsideKanbanItem = event.target.closest('.kanban-item');

                if (!isInsideKanbanItem) {
                    this.selectedUser = null;
                }
            },
            toggleDropdown(role) {
                if (this.dropdownRole === role) {
                    this.dropdownRole = null;
                    document.removeEventListener('click', this.handleClickOutside);
                    return;
                }

                this.dropdownRole = role;

                this.$nextTick(() => {
                    const dropdown = this.$refs[`dropdown-${role.attributes.id}`]?.[0];

                    if (dropdown) {
                        const rect = dropdown.getBoundingClientRect();
                        const windowWidth = window.innerWidth;
                        const overflowRight = rect.right > windowWidth;

                        dropdown.style.transform = overflowRight
                            ? `translateX(${windowWidth - rect.right - 10}px)`
                            : `translateX(0)`;
                    }

                    setTimeout(() => {
                        if (this.dropdownRole === role) {
                            document.addEventListener('click', this.handleClickOutside);
                        }
                    }, 100);
                });
            },
            toggleUserSelection(user) {
                this.selectedUser = this.selectedUser === user ? null : user;
            },
            async removeUser(user) {
                try {
                    await this.projectService.memberRemove({
                        project_id: this.project.id,
                        user_id: user.attributes.id,
                    });
                } catch (error) {
                    console.error('Error remove member from project request', error);
                }
                this.selectedUser = null;
            },
            addUserToRole(user, role) {
                const params = { role, user };

                this.updateUserRole(params);
            },
            drag(user, role) {
                if (role.attributes.name === 'owner') return;

                this.draggedUser = user;
                this.draggedFrom = role;
            },
            drop(role) {
                if (role.attributes.name === 'owner') return;

                if (this.draggedUser && this.draggedFrom !== role) {
                    const params = { user: this.draggedUser.attributes, role: role };
                    this.updateUserRole(params);
                }
                this.draggedUser = null;
                this.draggedFrom = null;
            },

            roleUsers(role) {
                let users = this.project.users
                    .filter(user => user.project_role_id === role.attributes.id)
                    .sort((a, b) => a.first_name.localeCompare(b.first_name))
                    .map(user => ({ attributes: user }));

                return users;
            },
            async updateUserRole(params) {
                const formData = new FormData();
                formData.append('id', this.project.id);
                formData.append('project[user_id]', params.user.id);
                formData.append('project[role_id]', params.role.id);

                try {
                    await this.projectService.memberCreate(formData);
                } catch (error) {
                    console.log('Error update user role', error);
                }
            },

            async fetchProject() {
                try {
                    const { data } = await this.projectService.memberList(this.$route.params.id);

                    this.project = data.data.attributes;
                } catch (error) {
                    console.log('Error fetching project', error);
                }
            },
            async fetchUsers() {
                try {
                    const { data } = await this.userService.getWithFilters({ disable_pagy: true });
                    this.allUsers = data.data;
                } catch (error) {
                    console.log('Error fetching users', error);
                }
            },

            async fetchRoles() {
                try {
                    const { data } = await this.roleService.getAll({ roles_type: 'project' });
                    this.roles = data.data;
                } catch (error) {
                    console.log('Error fetching roles', error);
                }
            },

            connectWebSocket() {
                if (!this.wsUrl || !this.project) return;

                if (this.ws && this.ws.readyState !== WebSocket.CLOSED) {
                    console.warn('WebSocket connection already exists');
                    return;
                }

                this.ws = new WebSocket(this.wsUrl);

                this.ws.onopen = () => {
                    retries = 0;

                    const subProject = {
                        command: 'subscribe',
                        identifier: JSON.stringify({
                            channel: 'ProjectChannel',
                            project_id: this.project.id,
                        }),
                    };

                    this.ws.send(JSON.stringify(subProject));
                };

                this.ws.onmessage = event => {
                    const data = JSON.parse(event.data);
                    if (data.type !== 'ping' && data.message) {
                        const { action, project } = data.message;

                        const actions = {
                            new_member: () => this.wsNewMember(project),
                            remove_member: () => this.wsRemoveMember(project),
                        };

                        if (actions[action]) {
                            actions[action]();
                        } else {
                            console.warn(`Unhandled action: ${action}`);
                        }
                    }
                };

                this.ws.onclose = () => {
                    if (process.env.NODE_ENV === 'development') {
                        console.warn('WebSocket connection closed');
                    }
                    this.retryWebSocketConnection();
                };

                this.ws.onerror = error => {
                    this.retryWebSocketConnection();
                };
            },
            retryWebSocketConnection() {
                if (retries < maxRetries) {
                    retries += 1;
                    setTimeout(() => {
                        this.connectWebSocket();
                    }, reconnectInterval);
                } else {
                    if (process.env.NODE_ENV === 'development') {
                        console.warn('The maximum number of reconnections has been reached. TimerChannel');
                    }
                }
            },

            wsNewMember(params) {
                this.project = params.data.attributes;
            },

            wsRemoveMember(params) {
                this.project.users = this.project.users.filter(u => u.id != params.user_id);
            },
        },
    };
</script>

<style scoped lang="scss">
    @import '../../styles/actions/Members.scss';
</style>
