<template>
    <div ref="dropdown" :class="{ hide: !visible }" class="filters-dropdown">
        <button class="close-btn" @click="$emit('close')">&times;</button>
        <div class="filter-selector">
            <label> User: </label>
            <UserSelect :value="filters.users" @change="handleUserFilterChange" />
        </div>
        <button
            class="clear-btn at-btn crud__control-items__item at-btn--primary at-btn--primary at-btn--large"
            @click="clearFilters"
        >
            Clear Filters
        </button>
    </div>
</template>

<script>
    import UserSelect from '../../../components/UserSelect.vue';

    export default {
        components: { UserSelect },
        props: {
            visible: {
                type: Boolean,
                default: false,
            },
        },
        data() {
            return {
                filters: {
                    users: [],
                },
                isOpening: false,
            };
        },
        watch: {
            visible(newVal) {
                if (newVal) {
                    this.isOpening = true;
                    setTimeout(() => {
                        this.isOpening = false;
                    }, 100);
                }
            },
        },
        mounted() {
            document.addEventListener('click', this.handleClickOutside);
        },
        beforeDestroy() {
            document.removeEventListener('click', this.handleClickOutside);
        },
        methods: {
            handleUserFilterChange(value) {
                this.filters.users = value;
                this.handleFilterChange();
            },
            handleFilterChange() {
                this.$emit('apply-filters', this.filters);
            },
            clearFilters() {
                this.filters = {
                    users: [],
                };
                this.handleFilterChange();
            },
            handleClickOutside(event) {
                if (this.isOpening) return;
                if (this.visible && this.$refs.dropdown && !this.$refs.dropdown.contains(event.target)) {
                    this.$emit('close');
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    .filters-dropdown {
        position: absolute;
        top: 30%;
        right: 15%;
        background: white;
        padding: 16px;
        border: 1px solid #e9ecef;
        border-radius: 8px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        display: flex;
        flex-direction: column;
        width: 300px;
        z-index: 2;

        label {
            margin-bottom: 10px;
            font-size: 14px;
            display: block;
        }

        .theme-dark & {
            background: #2b2b2b;
            color: #c4c4cf;
            border-color: #3a3a3a;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }
    }

    .hide {
        display: none;
    }

    .filter-selector {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
    }

    .close-btn {
        background: none;
        border: none;
        font-size: 18px;
        font-weight: bold;
        cursor: pointer;
        align-self: flex-end;
    }
</style>
