<template>
    <div class="task-form">
        <button class="back-button" @click="$router.go(-1)">&larr; Back</button>
        <h2>Create Project</h2>
        <form @submit.prevent="submitForm">
            <div class="form-group" :class="{ 'has-error': errors.name }">
                <label for="title">Name</label>
                <input id="title" v-model="form.name" type="text" required />
                <span v-if="errors.name" class="error-message">{{ errors.name }}</span>
            </div>

            <div class="form-group" :class="{ 'has-error': errors.shortcut }">
                <label for="title">Shortcut</label>
                <input id="title" v-model="form.shortcut" type="text" />
                <span v-if="errors.shortcut" class="error-message">{{ errors.shortcut }}</span>
            </div>

            <div class="form-group" :class="{ 'has-error': errors.description }">
                <label for="description">Description</label>
                <textarea id="description" v-model="form.description"></textarea>
                <span v-if="errors.description" class="error-message">{{ errors.description }}</span>
            </div>

            <!-- Multi-Select Collaborators Dropdown -->
            <div class="form-group">
                <label for="collaborators">Collaborators</label>
                <div ref="companyDropdown" class="selector" @click="toggleDropdown">
                    <div class="selected-options">
                        <template v-if="selectedCompanies.length">
                            <span v-for="company in visibleCompanies" :key="company.attributes.id" class="badge">
                                {{ company.attributes.name }}
                            </span>
                            <span v-if="hiddenCount > 0" class="more-badge"> +{{ hiddenCount }} more </span>
                        </template>
                        <span v-else>Select collaborators</span>
                    </div>
                    <div v-if="dropdownCompanyOpen" class="dropdown-list">
                        <div
                            v-for="company in companies"
                            :key="company.attributes.id"
                            class="dropdown-item"
                            @click.stop="toggleSelection(company)"
                        >
                            <input type="checkbox" :checked="isSelected(company)" />
                            {{ company.attributes.name }}
                        </div>
                    </div>
                    <div class="selector-icons">
                        <span
                            v-show="selectedCompanies.length"
                            class="clear-select"
                            @click.stop="clearSelectedCompanies"
                        >
                            <font-awesome-icon :icon="['fas', 'xmark']" />
                        </span>
                        <span
                            class="icon icon-chevron-down at-select__arrow"
                            :class="{ rotated: dropdownCompanyOpen }"
                        />
                    </div>
                </div>
            </div>

            <!-- Color Picker -->
            <div class="form-group">
                <label for="color">Color</label>
                <color-input :value="form.color" @change="handleChangeColor" />
            </div>

            <button type="submit" class="submit-button">Create Task</button>
        </form>
    </div>
</template>

<script>
    import ColorInput from '@/components/ColorInput.vue';
    import ClientService from '../../../Client/services/client.service';
    import ProjectService from '@/services/resource/project.service';

    export default {
        components: {
            ColorInput,
        },
        data() {
            return {
                form: {
                    name: '',
                    shortcut: '',
                    description: '',
                    color: this.getRandomColor(),
                    collaborators: [],
                },
                errors: {},
                dropdownCompanyOpen: false,
                companies: [],
                selectedCompanies: [],

                service: new ProjectService(),
                clientService: new ClientService(),
            };
        },
        computed: {
            visibleCompanies() {
                return this.selectedCompanies.slice(0, 3);
            },
            hiddenCount() {
                return this.selectedCompanies.length > 3 ? this.selectedCompanies.length - 3 : 0;
            },
        },
        mounted() {
            document.addEventListener('click', this.closeDropdown);
        },
        beforeDestroy() {
            document.removeEventListener('click', this.closeDropdown);
        },
        created() {
            this.fetchClientCompanies();
        },
        methods: {
            closeDropdown(event) {
                if (!this.$refs.companyDropdown.contains(event.target)) {
                    this.dropdownCompanyOpen = false;
                }
            },
            clearSelectedCompanies() {
                this.selectedCompanies = [];
            },

            validateForm() {
                this.errors = {};

                if (!this.form.name) {
                    this.errors.name = 'Field must be filled';
                } else if (this.form.name.length < 2) {
                    this.errors.name = 'Name must be at least 2 characters long';
                }

                if (!this.form.description) {
                    this.errors.description = 'Field must be filled';
                } else if (this.form.description.length < 3) {
                    this.errors.description = 'Description must be at least 3 characters long';
                }

                return Object.keys(this.errors).length === 0;
            },

            submitForm() {
                if (!this.validateForm()) return;

                this.createProjectRequest();
            },

            async createProjectRequest() {
                const formData = new FormData();
                formData.append('project[name]', this.form.name);
                formData.append('project[shortcut]', this.form.shortcut);
                formData.append('project[description]', this.form.description);
                formData.append('project[color]', this.form.color);

                if (this.selectedCompanies.length) {
                    this.selectedCompanies.forEach(client => {
                        formData.append('companies[]', client.attributes.company_id);
                    });
                }

                try {
                    const { data } = await this.service.save(formData);

                    this.successSaveMessage();
                    this.$router.push({ name: 'project.view', params: { id: data.data.id } });
                } catch (error) {
                    console.log('Error create project request', error);
                }
            },

            getRandomColor() {
                return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
            },
            handleChangeColor(color) {
                this.form.color = color;
            },
            toggleDropdown() {
                this.dropdownCompanyOpen = !this.dropdownCompanyOpen;
            },
            toggleSelection(company) {
                this.selectedCompanies = this.selectedCompanies.includes(company)
                    ? this.selectedCompanies.filter(c => c.id !== company.id)
                    : [...this.selectedCompanies, company];
            },
            isSelected(collaborator) {
                return this.selectedCompanies.some(c => c.id === collaborator.id);
            },

            successSaveMessage() {
                this.$Notify({
                    type: 'success',
                    title: this.$t('notification.save.success.title'),
                    message: 'Project created successfully!',
                });
            },

            async fetchClientCompanies() {
                try {
                    const { data } = await this.clientService.getWithFilters({ disable_pagy: true });
                    const companies = data.data.filter(company => company.attributes.company_id != null);

                    this.companies = companies;
                } catch (error) {
                    console.log('User request error', error);
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    @import '../../styles/actions/New.scss';
</style>
