import axios from 'axios';

class LogoService {
    create(data) {
        return axios.post('/api/v1/logos', data);
    }

    destroy(id) {
        return axios.delete(`/api/v1/logos/${id}`);
    }
}

export default LogoService;
