<template>
    <div class="project">
        <div class="project__content-wrapper">
            <router-view :key="$route.fullPath" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Projects',

        beforeRouteEnter(to, from, next) {
            if (to.path === '/projects' || to.path === 'projects/') {
                next('/projects/list');
            } else {
                next();
            }
        },

        beforeRouteUpdate(to, from, next) {
            if (to.path === '/projects' || to.path === 'projects/') {
                this.$router.replace('/projects/list').catch(err => {
                    if (err.name !== 'NavigationDuplicated') {
                        throw err;
                    }
                });
            } else {
                next();
            }
        },
    };
</script>

<style lang="scss" scoped></style>
