<template>
    <div v-if="task" class="task-view">
        <div class="top-bar">
            <div class="header-left-section">
                <span class="task-color" :style="{ backgroundColor: task.color }"></span>
                <p class="task-ref">{{ task.ref }}</p>
            </div>
            <div v-click-outside="closeMoreActionsDropDown" class="top-actions">
                <button v-if="hasUpdate" class="icon-button" @click="moreActionsDropdown = !moreActionsDropdown">
                    <font-awesome-icon :icon="['fas', 'ellipsis']" />
                </button>

                <div v-if="moreActionsDropdown" class="more-actions-menu">
                    <div class="dropdown-section">
                        <button class="dropdown-item" @click="toggleColorPicker">
                            <font-awesome-icon :icon="['fas', 'palette']" />
                            Change Color
                        </button>

                        <div v-if="showColorPickerDropdown" class="color-picker-dropdown">
                            <label class="dropdown-label">Select New Color:</label>
                            <chrome-picker :value="selectedColor" @input="selectedColor = $event" />

                            <div class="color-actions">
                                <button
                                    class="save-name-btn at-btn crud__control-items__item at-btn--primary at-btn--primary at-btn--large"
                                    @click="saveColor"
                                >
                                    <font-awesome-icon :icon="['fas', 'check']" />
                                </button>
                                <button
                                    class="cancel-name-btn at-btn at-btn--error at-btn--large action-button"
                                    @click="closeColorPicker"
                                >
                                    <font-awesome-icon :icon="['fas', 'xmark']" />
                                </button>
                            </div>
                        </div>
                    </div>

                    <div v-if="hasDelete" class="delete-section">
                        <div class="dropdown-separator"></div>

                        <button class="dropdown-item destroy-task-btn" @click="deleteTask()">
                            <font-awesome-icon :icon="['fas', 'trash']" />
                            {{ $t('task.delete') }}
                        </button>
                    </div>
                </div>

                <button class="back-button" @click="backButtonPath">{{ $t('task.back') }}</button>
            </div>
        </div>

        <div class="content">
            <div class="task-details">
                <div class="view-header">
                    <div v-if="!isEditingName" class="task-title" @click="editTaskName">
                        <h1>{{ task.task_name }}</h1>
                    </div>
                    <div v-else class="edit-task-name">
                        <input
                            ref="taskNameInput"
                            v-model="editedTaskName"
                            class="input task-name-input"
                            @keyup.enter="saveTaskName"
                            @keyup.esc="cancelEditTaskName"
                        />
                        <div class="edit-task-name-buttons">
                            <button
                                class="save-name-btn at-btn crud__control-items__item at-btn--primary at-btn--primary at-btn--large"
                                @click="saveTaskName"
                            >
                                <font-awesome-icon :icon="['fas', 'check']" />
                            </button>
                            <button
                                class="cancel-name-btn at-btn at-btn--error at-btn--large action-button"
                                @click="cancelEditTaskName"
                            >
                                <font-awesome-icon :icon="['fas', 'xmark']" />
                            </button>
                        </div>
                    </div>
                </div>

                <div class="header-button-section">
                    <button v-click-outside="closeDropDown" class="add-button" @click="toggleDropdown">
                        + {{ $t('task.add') }}
                    </button>
                    <div v-if="showDropdown" class="dropdown-menu">
                        <button class="dropdown-item" @click="attachFile">
                            <font-awesome-icon :icon="['fas', 'paperclip']" />
                            {{ $t('task.attach_file') }}
                        </button>
                        <button class="dropdown-item" @click="addLink">
                            <font-awesome-icon :icon="['fas', 'link']" />
                            {{ $t('task.add_link') }}
                        </button>
                    </div>
                </div>

                <div class="left-section">
                    <label for="description" class="section-title">{{ $t('task.description') }}</label>

                    <div v-if="!isEditing" class="description-view" @click="editDescription">
                        {{ strippedDescription || $t('task.click_to_add_description') }}
                    </div>

                    <div v-else>
                        <vue-editor v-model="editedDescription" :editorToolbar="editorToolbar" class="editor" />
                        <div class="editor-buttons">
                            <button
                                class="save-button at-btn crud__control-items__item at-btn--primary at-btn--large"
                                @click="saveDescription"
                            >
                                {{ $t('task.save') }}
                            </button>
                            <button class="cancel-button" @click="closeEditDescription">{{ $t('task.cancel') }}</button>
                        </div>
                    </div>
                </div>

                <div v-if="task.files.length || showAttchFiles || attachmentsOpenedOnce" class="left-section">
                    <attachments :task="task" :openForm="showAttchFiles" @formClosed="showAttchFiles = false" />
                </div>

                <div v-if="task.links.length || showAddLinks" class="left-section">
                    <web-links :task="task" />
                </div>

                <div class="left-section">
                    <task-activity :task="task" :wsAddChangeLog="wsChangeLogObject" />
                </div>
            </div>

            <!-- Right Column -->
            <div class="task-sidebar">
                <task-details-bar :task="task" />
            </div>
        </div>

        <delete-modal
            :visible="deleteModalVisible"
            :title="$t('delete_modal.title')"
            :message="$t('delete_modal.message')"
            @confirm="confirmDelete"
            @close="closeDeleteModal"
        />
    </div>
</template>

<script>
    import { VueEditor } from 'vue2-editor';
    import Attachments from '../../components/Attachments.vue';
    import WebLinks from '../../components/WebLinks.vue';
    import TaskActivity from '../../components/TaskActivity.vue';
    import TaskDetailsBar from '../../components/TaskDetailsBar.vue';
    import DeleteModal from '../../../../components/DeleteModal.vue';
    import TasksService from '../../../../services/resource/task.service';
    import TaskPolicy from '@/policies/task.policy.js';
    import { Chrome } from 'vue-color';
    import { mapGetters } from 'vuex';

    let reconnectInterval = 5000;
    let maxRetries = 10;
    let retries = 0;

    export default {
        components: {
            VueEditor,
            DeleteModal,
            Attachments,
            WebLinks,
            TaskActivity,
            TaskDetailsBar,
            chromePicker: Chrome,
        },
        props: {
            taskID: { type: String, default: null },
        },
        data() {
            return {
                showDropdown: false,
                showAttchFiles: false,
                attachmentsOpenedOnce: false,
                showAddLinks: false,
                moreActionsDropdown: false,
                showColorPickerDropdown: false,
                selectedColor: '',

                deleteModalVisible: false,

                isEditing: false,
                isEditingName: false,
                editedTaskName: '',
                editedDescription: '',

                task: null,
                editorToolbar: [
                    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
                    [{ indent: '-1' }, { indent: '+1' }],
                    [{ color: [] }, { background: [] }],
                ],

                service: new TasksService(),

                wsChangeLogObject: null,

                wsUrl: process.env.VUE_APP_WS_URL || '',
                ws: null,
            };
        },
        computed: {
            ...mapGetters('user', ['user']),
            strippedDescription() {
                return this.task.description ? this.stripHtml(this.task.description) : '';
            },
            hasUpdate() {
                return TaskPolicy.update(this.user);
            },
            hasDelete() {
                return TaskPolicy.delete(this.user);
            },
        },
        async created() {
            await this.fetchTask();
            this.connectWebSocket();
        },

        methods: {
            toggleColorPicker() {
                this.selectedColor = this.task.color;
                this.showColorPickerDropdown = !this.showColorPickerDropdown;
            },

            backButtonPath() {
                if (this.$route.path == '/kanban/board') {
                    this.$emit('close');
                    return;
                }
                this.$router.go(-1);
            },
            closeColorPicker() {
                this.showColorPickerDropdown = false;
                this.selectedColor = this.task.color;
            },
            async saveColor() {
                if (this.selectedColor !== this.task.color) {
                    try {
                        const formData = new FormData();
                        formData.append('task[color]', this.selectedColor.hex);

                        await this.service.update(formData, this.task.id);
                        this.task.color = this.selectedColor.hex;
                    } catch (error) {
                        console.error('Error Update color', error);
                    }
                }
                this.showColorPickerDropdown = false;
            },
            editTaskName() {
                if (!this.hasUpdate) return;

                this.isEditingName = true;
                this.editedTaskName = this.task.task_name;
                this.$nextTick(() => {
                    this.$refs.taskNameInput.focus();
                });
            },
            async saveTaskName() {
                if (this.editedTaskName.trim() && this.editedTaskName !== this.task.task_name) {
                    try {
                        const formData = new FormData();
                        formData.append('task[task_name]', this.editedTaskName);

                        await this.service.update(formData, this.task.id);

                        this.task.task_name = this.editedTaskName;
                        this.successSaveMessage();
                    } catch (error) {
                        console.error('Error update task name request', error);
                    }
                }
                this.isEditingName = false;
            },
            cancelEditTaskName() {
                this.isEditingName = false;
                this.editedTaskName = this.task.task_name;
            },
            editDescription() {
                if (!this.hasUpdate) return;

                this.editedDescription = this.task.description;
                this.isEditing = true;
            },
            closeEditDescription() {
                this.isEditing = false;
            },
            closeDeleteModal() {
                this.deleteModalVisible = false;
            },
            deleteTask() {
                this.deleteModalVisible = true;
            },

            confirmDelete() {
                this.service.deleteItem(this.task.id).then(() => {
                    this.deleteModalVisible = false;
                    this.$Notify({
                        type: 'success',
                        title: this.$t('notification.record.delete.success.title'),
                        message: this.$t('notification.record.delete.success.message'),
                    });
                    this.$router.push({ name: 'task.list' });
                });
            },
            async saveDescription() {
                const formData = new FormData();
                formData.append('task[description]', this.editedDescription);

                try {
                    await this.service.update(formData, this.task.id);

                    this.task.description = this.editedDescription;
                    this.successSaveMessage();
                } catch (error) {
                    console.log('Error update Description request', error);
                }

                this.isEditing = false;
            },
            toggleDropdown() {
                this.showDropdown = !this.showDropdown;
            },
            attachFile() {
                this.showAttchFiles = true;
                this.showDropdown = false;
                this.attachmentsOpenedOnce = true;
            },
            addLink() {
                this.showAddLinks = true;
                this.showDropdown = false;
            },

            closeDropDown() {
                this.showDropdown = false;
            },
            closeMoreActionsDropDown() {
                this.moreActionsDropdown = false;
                this.showColorPickerDropdown = false;
            },

            stripHtml(html) {
                return html.replace(/<[^>]*>/g, '');
            },

            async fetchTask() {
                const taskId = this.$route.params.id || this.taskID;
                try {
                    const { data } = await this.service.getItem(taskId);
                    this.task = data.data.attributes;
                } catch (error) {
                    console.log('Error requst Task Show', error);
                }
            },
            successSaveMessage() {
                this.$Notify({
                    type: 'success',
                    title: this.$t('notification.save.success.title'),
                    message: this.$t('notification.save.success.message'),
                });
            },

            connectWebSocket() {
                if (!this.wsUrl || !this.task) return;

                if (this.ws && this.ws.readyState !== WebSocket.CLOSED) {
                    console.warn('WebSocket connection already exists');
                    return;
                }

                this.ws = new WebSocket(this.wsUrl);

                this.ws.onopen = () => {
                    retries = 0;

                    const subTask = {
                        command: 'subscribe',
                        identifier: JSON.stringify({
                            channel: 'TaskChannel',
                            task_id: this.task.id,
                        }),
                    };

                    this.ws.send(JSON.stringify(subTask));
                };

                this.ws.onmessage = event => {
                    const data = JSON.parse(event.data);
                    if (data.type !== 'ping' && data.message) {
                        const { action, changes_log, task } = data.message;

                        const actions = {
                            add_changes_log: () => this.wsAddNewChangesLog(changes_log),
                            update_task: () => this.wsUpdateTask(task),
                            new_task_tags: () => this.wsUpdateTaskTags(task),
                        };

                        if (actions[action]) {
                            actions[action]();
                        } else {
                            console.warn(`Unhandled action: ${action}`);
                        }
                    }
                };

                this.ws.onclose = () => {
                    if (process.env.NODE_ENV === 'development') {
                        console.warn('WebSocket connection closed');
                    }
                    this.retryWebSocketConnection();
                };

                this.ws.onerror = error => {
                    this.retryWebSocketConnection();
                };
            },
            retryWebSocketConnection() {
                if (retries < maxRetries) {
                    retries += 1;
                    setTimeout(() => {
                        this.connectWebSocket();
                    }, reconnectInterval);
                } else {
                    if (process.env.NODE_ENV === 'development') {
                        console.warn('The maximum number of reconnections has been reached. TimerChannel');
                    }
                }
            },

            wsAddNewChangesLog(data) {
                this.wsChangeLogObject = data;
            },

            wsUpdateTask(data) {
                this.task = data.data.attributes;
            },

            wsUpdateTaskTags(data) {
                this.task.tags = data.tags.data;
                this.$set(this.task, 'tags', data.tags.data);
            },
        },
    };
</script>

<style scoped lang="scss">
    @import '../../styles/actions/View.scss';
</style>
