import axios from 'axios';

class TaskLinkService {
    save(data) {
        return axios.post('/api/v1/task_links', data);
    }

    delete(id) {
        return axios.delete(`/api/v1/task_links/${id}`);
    }
}

export default TaskLinkService;
