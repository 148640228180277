<template>
    <div class="project-users">
        <div v-for="user in visibleUsers" :key="user.attributes.id">
            <at-tooltip :content="`${user.attributes.first_name} ${user.attributes.last_name}`" :placement="'top'">
                <user-avatar :user="user.attributes" />
            </at-tooltip>
        </div>

        <span
            v-if="hiddenUsers.length > 0"
            class="more-users"
            @mouseenter="showTooltip($event, hiddenUsers)"
            @mouseleave="hideTooltip"
        >
            +{{ hiddenUsers.length }} more
        </span>

        <div v-if="tooltip.visible" class="tooltip" :style="tooltip.position">
            <div v-for="user in tooltip.users" :key="user.attributes.id" class="tooltip-user">
                <user-avatar :user="user.attributes" class="tooltip-avatar" />
                <span>{{ user.attributes.first_name }} {{ user.attributes.last_name }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import UserAvatar from '@/components/UserAvatar.vue';
    export default {
        components: { UserAvatar },
        props: {
            users: {
                type: Array,
                required: true,
            },
            maxVisible: {
                type: Number,
                default: 1,
            },
        },
        data() {
            return {
                tooltip: {
                    visible: false,
                    users: [],
                    position: {},
                    timeout: null,
                },
            };
        },
        computed: {
            visibleUsers() {
                return this.users.slice(0, this.maxVisible);
            },
            hiddenUsers() {
                return this.users.slice(this.maxVisible);
            },
        },
        methods: {
            showTooltip(event, users) {
                const tooltipWidth = 200;
                const offsetX = 10;
                const offsetY = 10;
                clearTimeout(this.tooltip.timeout);
                this.tooltip.timeout = setTimeout(() => {
                    this.tooltip.users = users;
                    this.tooltip.position = {
                        top: `${event.clientY + offsetY}px`,
                        left: `${Math.min(event.clientX + offsetX, window.innerWidth - tooltipWidth)}px`,
                        width: `${tooltipWidth}px`,
                    };
                    this.tooltip.visible = true;
                }, 300);
            },
            hideTooltip() {
                clearTimeout(this.tooltip.timeout);
                this.tooltip.visible = false;
            },
        },
    };
</script>

<style scoped lang="scss">
    .project-users {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;
        align-items: center;
    }

    .more-users {
        font-size: 12px;
        color: #6c757d;
        cursor: pointer;

        .theme-dark & {
            color: #e0e0e0;
        }
    }

    .more-users:hover {
        text-decoration: underline;
    }

    .tooltip {
        position: absolute;
        background-color: #ffffff;
        border: 1px solid #ced4da;
        padding: 10px;
        border-radius: 8px;
        padding: 8px;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        z-index: 1000;
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding: 8px 10px 15px 10px;

        .theme-dark & {
            background-color: #333;
            border-color: #555;
            color: #e0e0e0;
        }
    }

    .tooltip-user {
        display: flex;
        align-items: center;
        gap: 15px;
    }

    .tooltip-avatar {
        width: 32px;
        height: 32px;
        border-radius: 50%;
        object-fit: cover;
    }
</style>
