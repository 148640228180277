<template>
    <div class="task-form">
        <button class="back-button" @click="$router.go(-1)">← Back</button>
        <h2>Create Task</h2>
        <form @submit.prevent="submitForm">
            <!-- Task Name -->
            <div class="form-group" :class="{ 'has-error': errors.task_name }">
                <label for="task_name">Task Name</label>
                <input id="task_name" v-model="form.task_name" type="text" required placeholder="Enter task name" />
                <span v-if="errors.task_name" class="error-message">{{ errors.task_name }}</span>
            </div>

            <!-- Description -->
            <div class="form-group" :class="{ 'has-error': errors.description }">
                <label for="description">Description</label>
                <vue-editor v-model="form.description" :editorToolbar="editorToolbar" class="editor" />
                <span v-if="errors.description" class="error-message">{{ errors.description }}</span>
            </div>

            <!-- Select Project -->
            <div class="form-group" :class="{ 'has-error': errors.project_id }">
                <label for="project_id">Project</label>
                <div ref="projectDropdown" class="selector" @click="toggleProjectDropdown">
                    <div class="selected-option">
                        {{ selectedProject ? selectedProject.attributes.name : 'Select a project' }}
                    </div>
                    <div v-if="dropdownProjectOpen" class="dropdown-list">
                        <div
                            v-for="project in projects"
                            :key="project.id"
                            class="dropdown-item"
                            @click="selectProject(project)"
                        >
                            {{ project.attributes.name }}
                        </div>
                    </div>
                    <div class="selector-icons">
                        <span v-show="selectedProject" class="clear-select" @click="clearProjectSelect"
                            ><font-awesome-icon :icon="['fas', 'xmark']"
                        /></span>
                        <span
                            class="icon icon-chevron-down at-select__arrow"
                            :class="{ rotated: dropdownProjectOpen }"
                        />
                    </div>
                </div>
                <span v-if="errors.project_id" class="error-message">{{ errors.project_id }}</span>
            </div>

            <!-- Select Assignees (Hidden or Disabled Initially) -->
            <div v-if="form.project_id" class="form-group">
                <label for="assignees">Assignees</label>
                <user-project-select :selectedProjectID="form.project_id" @change="handleAssigneesUsers" />
            </div>

            <!-- Priority -->
            <div class="form-group" :class="{ 'has-error': errors.priority_id }">
                <label for="priority">Priority</label>
                <div ref="priorityDropdown" class="selector" @click="togglePriorityDropdown">
                    <div class="selected-option">
                        {{ selectedPriority ? selectedPriority.attributes.name : 'Select a priority' }}
                    </div>
                    <div v-if="dropdownPriorityOpen" class="dropdown-list">
                        <div
                            v-for="priority in priorities"
                            :key="priority.attributes.id"
                            class="dropdown-item"
                            @click="selectPriority(priority)"
                        >
                            {{ priority.attributes.name }}
                        </div>
                    </div>
                    <div class="selector-icons">
                        <span v-show="selectedPriority" class="clear-select" @click.stop="clearPrioritySelect">
                            <font-awesome-icon :icon="['fas', 'xmark']" />
                        </span>
                        <span
                            class="icon icon-chevron-down at-select__arrow"
                            :class="{ rotated: dropdownPriorityOpen }"
                        />
                    </div>
                </div>
                <span v-if="errors.priority_id" class="error-message">{{ errors.priority_id }}</span>
            </div>

            <!-- Status -->
            <div class="form-group" :class="{ 'has-error': errors.status_id }">
                <label for="status">Status</label>
                <div ref="statusDropdown" class="selector" @click="toggleStatusDropdown">
                    <div class="selected-option">
                        {{ selectedStatus ? selectedStatus.attributes.name : 'Select a status' }}
                    </div>
                    <div v-if="dropdownStatusOpen" class="dropdown-list">
                        <div
                            v-for="status in statuses"
                            :key="status.attributes.id"
                            class="dropdown-item"
                            @click="selectStatus(status)"
                        >
                            {{ status.attributes.name }}
                        </div>
                    </div>
                    <div class="selector-icons">
                        <span v-show="selectedStatus" class="clear-select" @click.stop="clearStatusSelect">
                            <font-awesome-icon :icon="['fas', 'xmark']" />
                        </span>
                        <span
                            class="icon icon-chevron-down at-select__arrow"
                            :class="{ rotated: dropdownStatusOpen }"
                        />
                    </div>
                </div>
                <span v-if="errors.status_id" class="error-message">{{ errors.status_id }}</span>
            </div>

            <!-- Select Sprint (Hidden if No Sprints) -->
            <div v-if="sprints.length > 0" class="form-group">
                <label for="sprint">Sprint</label>
                <div ref="sprintDropdown" class="selector" @click="toggleSprintDropdown">
                    <div class="selected-option">
                        {{ selectedSprint ? selectedSprint.attributes.name : 'No Sprint' }}
                    </div>
                    <div v-if="dropdownSprintOpen" class="dropdown-list">
                        <div class="dropdown-item" @click="selectSprint(null)">No Sprint</div>
                        <div
                            v-for="sprint in sprints"
                            :key="sprint.attributes.id"
                            class="dropdown-item"
                            @click="selectSprint(sprint)"
                        >
                            {{ sprint.attributes.name }}
                        </div>
                    </div>
                    <div class="selector-icons">
                        <span v-show="selectedSprint" class="clear-select" @click.stop="clearSprintSelect">
                            <font-awesome-icon :icon="['fas', 'xmark']" />
                        </span>
                        <span
                            class="icon icon-chevron-down at-select__arrow"
                            :class="{ rotated: dropdownSprintOpen }"
                        />
                    </div>
                </div>
            </div>

            <!-- Estimated Points (Only if Sprint Selected) -->
            <div v-if="form.sprint_id" class="form-group">
                <label for="estimated_points">Estimated Points</label>
                <input
                    id="estimated_points"
                    v-model.number="form.estimated_points"
                    type="number"
                    min="1"
                    placeholder="Enter estimation"
                />
            </div>

            <!-- Deadline -->
            <div class="form-group">
                <label for="deadline">Deadline</label>
                <div class="picker">
                    <datePicker
                        v-model="form.deadline"
                        :format="'yyyy-MM-dd'"
                        :typeable="true"
                        :clearable="true"
                        :editable="true"
                        :year-selector="true"
                        :placeholder="'Choose date'"
                        input-class="at-input__original"
                    ></datePicker>
                </div>
            </div>

            <!-- Color Picker -->
            <div class="form-group">
                <label for="color">Color</label>
                <color-input :value="form.color" @change="handleChangeColor" />
            </div>

            <!-- Submit Button -->
            <button type="submit" class="submit-button">Create Task</button>
        </form>
    </div>
</template>

<script>
    import { VueEditor } from 'vue2-editor';
    import ColorInput from '@/components/ColorInput.vue';
    import UserProjectSelect from '@/components/UserProjectSelect.vue';
    import ProjectService from '@/services/resource/project.service';
    import PriorityService from '../../../Priorities/services/priority.service';
    import StatusesService from '../../../Statuses/services/statuse.service';
    import SprintService from '../../../sprints/services/sprint.service';
    import TaskService from '../../../../services/resource/task.service';
    import DatePicker from 'vuejs-datepicker';
    import { mapGetters } from 'vuex';

    export default {
        components: { VueEditor, ColorInput, UserProjectSelect, DatePicker },
        data() {
            return {
                dropdownProjectOpen: false,
                dropdownPriorityOpen: false,
                dropdownStatusOpen: false,
                dropdownSprintOpen: false,

                selectedProject: null,
                selectedPriority: null,
                selectedStatus: null,
                selectedSprint: null,

                form: {
                    task_name: '',
                    description: '',
                    project_id: null,
                    users: [],
                    priority_id: null,
                    status_id: null,
                    sprint_id: '',
                    estimated_points: 0,
                    deadline: '',
                    color: this.getRandomColor(),
                },
                errors: {
                    task_name: '',
                    description: '',
                    project_id: null,
                    priority_id: null,
                    status_id: null,
                },
                projects: [],
                priorities: [],
                statuses: [],
                sprints: [],

                editorToolbar: [
                    [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                    ['bold', 'italic', 'underline', 'strike'],
                    [{ list: 'ordered' }, { list: 'bullet' }, { list: 'check' }],
                    [{ indent: '-1' }, { indent: '+1' }],
                    [{ color: [] }, { background: [] }],
                ],

                projectService: new ProjectService(),
                priorityService: new PriorityService(),
                statusService: new StatusesService(),
                sprintService: new SprintService(),
                taskService: new TaskService(),
            };
        },
        computed: {
            ...mapGetters('company', ['settings']),
        },
        async created() {
            await this.fetchProjects();
            await this.fetchPriority();
            await this.fetchStatuses();
            await this.fetchSprints();

            this.assignedDefaultValues();
        },
        mounted() {
            document.addEventListener('click', this.closeDropdown);
        },
        beforeDestroy() {
            document.removeEventListener('click', this.closeDropdown);
        },
        methods: {
            toggleProjectDropdown() {
                this.dropdownProjectOpen = !this.dropdownProjectOpen;
            },
            togglePriorityDropdown() {
                this.dropdownPriorityOpen = !this.dropdownPriorityOpen;
            },
            toggleStatusDropdown() {
                this.dropdownStatusOpen = !this.dropdownStatusOpen;
            },
            toggleSprintDropdown() {
                this.dropdownSprintOpen = !this.dropdownSprintOpen;
            },

            selectProject(project) {
                this.selectedProject = project;
                this.form.project_id = project.id;
                this.dropdownProjectOpen = false;
            },
            selectPriority(priority) {
                this.selectedPriority = priority;
                this.form.priority_id = priority.id;
                this.dropdownPriorityOpen = false;
            },
            selectStatus(status) {
                this.selectedStatus = status;
                this.form.status_id = status.id;
                this.dropdownStatusOpen = false;
            },
            selectSprint(sprint) {
                this.selectedSprint = sprint;
                this.form.sprint_id = sprint.attributes.id;
                this.dropdownSprintOpen = false;
            },

            clearProjectSelect() {
                this.selectedProject = null;
                this.form.project_id = null;
            },
            clearPrioritySelect() {
                this.selectedPriority = null;
                this.form.priority_id = null;
            },
            clearStatusSelect() {
                this.selectedStatus = null;
                this.form.status_id = null;
            },
            clearSprintSelect() {
                this.selectedSprint = null;
                this.form.sprint_id = null;
            },

            closeDropdown(event) {
                if (!this.$refs.projectDropdown.contains(event.target)) {
                    this.dropdownProjectOpen = false;
                }
                if (!this.$refs.priorityDropdown.contains(event.target)) {
                    this.dropdownPriorityOpen = false;
                }
                if (!this.$refs.statusDropdown.contains(event.target)) {
                    this.dropdownStatusOpen = false;
                }
                if (!this.$refs.sprintDropdown.contains(event.target)) {
                    this.dropdownSprintOpen = false;
                }
            },

            validateForm() {
                let isValid = true;
                this.clearErrors();
                if (!this.form.task_name || this.form.task_name.length < 3) {
                    this.errors.task_name = 'Task Name size cannot be less than 3';
                    isValid = false;
                }
                if (!this.form.description) {
                    this.errors.description = 'Description must be filled';
                    isValid = false;
                }
                if (!this.form.project_id) {
                    this.errors.project_id = 'Project must be selected';
                    isValid = false;
                }
                if (!this.form.priority_id) {
                    this.errors.priority_id = 'Priority must be selected';
                    isValid = false;
                }
                if (!this.form.status_id) {
                    this.errors.status_id = 'Status must be selected';
                    isValid = false;
                }
                return isValid;
            },

            clearErrors() {
                this.errors = {
                    task_name: '',
                    description: '',
                    project_id: null,
                    priority_id: null,
                    status_id: null,
                };
            },
            submitForm() {
                if (!this.validateForm()) return;

                this.createTaskRequest();
            },
            getRandomColor() {
                return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
            },
            loadUsers() {
                const selectedProject = this.projects.find(p => p.id === this.form.project_id);
                this.users = selectedProject ? selectedProject.users : [];
            },
            handleAssigneesUsers(userIds) {
                this.form.users = userIds;
            },
            handleChangeColor(newColor) {
                this.form.color = newColor;
            },

            assignedDefaultValues() {
                const defaultPriority = this.priorities.find(
                    p => p.attributes.id === this.settings.default_priority_id,
                );
                if (defaultPriority) {
                    this.selectedPriority = defaultPriority;
                    this.form.priority_id = defaultPriority.attributes.id;
                }

                const defaultStatus = this.statuses.find(s => s.attributes.name === 'Created');

                if (defaultStatus) {
                    this.selectedStatus = defaultStatus;
                    this.form.status_id = defaultStatus.attributes.id;
                }
            },

            async createTaskRequest() {
                const formData = new FormData();
                formData.append('task[task_name]', this.form.task_name);
                formData.append('task[description]', this.form.description);
                formData.append('task[project_id]', this.form.project_id);
                formData.append('task[priority_id]', this.form.priority_id);
                formData.append('task[status_id]', this.form.status_id);
                formData.append('task[sprint_id]', this.form.sprint_id);
                formData.append('task[estimated_points]', this.form.estimated_points);
                formData.append('task[deadline]', this.form.deadline);
                formData.append('task[color]', this.form.color);

                this.form.users.forEach(id => {
                    formData.append('users[]', id);
                });

                try {
                    const { data } = await this.taskService.save(formData);

                    this.$router.push({ name: 'task.view', params: { id: data.data.id } });

                    this.$Notify({
                        type: 'success',
                        title: this.$t('notification.save.success.title'),
                        message: 'Task created successfuly',
                    });
                } catch (error) {
                    console.log('Error create task request', error);
                }
            },

            async fetchProjects() {
                try {
                    const { data } = await this.projectService.getWithFilters({ disable_page: true });
                    this.projects = data.data;
                } catch (error) {
                    console.log('Error project index request', error);
                }
            },

            async fetchPriority() {
                try {
                    const { data } = await this.priorityService.getWithFilters({ disable_page: true });
                    this.priorities = data.data;
                } catch (error) {
                    console.log('Error priority index request', error);
                }
            },

            async fetchStatuses() {
                try {
                    const { data } = await this.statusService.getWithFilters({ disable_page: true });
                    const statuses = data.data;

                    const filteredStatuses = statuses.filter(status =>
                        ['Created', 'In Progress'].includes(status.attributes.name),
                    );

                    this.statuses = filteredStatuses.length ? filteredStatuses : statuses;
                } catch (error) {
                    console.log('Error status index request', error);
                }
            },

            async fetchSprints() {
                try {
                    const { data } = await this.sprintService.getWithFilters({ disable_page: true });
                    this.sprints = data.data;
                } catch (error) {
                    console.log('Error sprint index request', error);
                }
            },
        },

        watch: {
            'form.project_id'(project_id) {
                this.fetchProjectById(project_id);
            },
        },
    };
</script>

<style scoped lang="scss">
    @import '../../styles/actions/New.scss';
</style>
