import axios from 'axios';

class TaskFileService {
    save(data) {
        return axios.post('/api/v1/task_files', data);
    }

    delete(id) {
        return axios.delete(`/api/v1/task_files/${id}`);
    }

    async download(fileId) {
        try {
            const response = await axios.get(`/api/v1/task_files/${fileId}/download`, {
                responseType: 'blob',
            });

            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;

            const contentDisposition = response.headers['content-disposition'];
            let fileName = 'downloaded_file';
            if (contentDisposition) {
                const matches = contentDisposition.match(/filename="?([^"]+)"?/);
                if (matches?.length > 1) fileName = decodeURIComponent(matches[1]);
            }

            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error while downloading file:', error);
        }
    }

    async bulkDownload(data, ref) {
        try {
            const response = await axios.post('/api/v1/task_files/bulk_download', data, { responseType: 'blob' });

            const blob = new Blob([response.data], { type: 'application/zip' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${ref}-task_files.zip`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading archive:', error);
        }
    }
}

export default TaskFileService;
