import axios from 'axios';

class TaskCommentService {
    save(data) {
        return axios.post('/api/v1/task-comment', data);
    }

    getAll(data, filters) {
        return axios.post('/api/v1/task-comment/list', data, filters);
    }

    update(data, id) {
        return axios.put(`/api/v1/task-comment/${id}`, data);
    }

    delete(id) {
        return axios.delete(`/api/v1/task-comment/${id}`);
    }
}

export default TaskCommentService;
